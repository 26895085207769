import React, { useState } from 'react';
import ClickButton from 'cccisd-click-button';
import CustomerSupportButton from '../common/CustomerSupportButton';
import EmployeeTime from '../common/EmployeeTime';
import { Field, Form, Formik, CccisdTextarea } from 'cccisd-formik';
import Modal from 'cccisd-modal';
import MonthlyStatusModal from '../common/MonthlyStatusModal';
import myEmployeesTimeQuery from './myEmployeesTime.graphql';
import SickTimeModal from '../common/SickTimeModal';
import utils from './utils';
import { useLocation } from 'react-router-dom';
import VacationModal from '../common/VacationModal';

export default ({ myEmployees, myFullName }) => {
    const findEmployee = id => myEmployees.find(employee => employee.employeeId === parseInt(id, 10));

    const urlSearchParams = new URLSearchParams(useLocation().search);
    const initialEmployeeId = urlSearchParams.get('employee');
    const initialEmployee = findEmployee(initialEmployeeId);

    if (initialEmployeeId && !initialEmployee) {
        return null;
    }

    const [selectedEmployee, setSelectedEmployee] = useState(initialEmployee || myEmployees[0]);

    const changeEmployee = event => setSelectedEmployee(findEmployee(event.target.value));

    return (
        <>
            <EmployeeTime
                timeQuery={myEmployeesTimeQuery}
                employeeId={selectedEmployee.employeeId}
                renderHeader={isLoading => {
                    return (
                        <select
                            className="form-control"
                            value={selectedEmployee.employeeId}
                            onChange={changeEmployee}
                            disabled={isLoading}
                        >
                            {myEmployees.map(employee => (
                                <option key={employee.employeeId} value={employee.employeeId}>
                                    {employee.user.fullName}
                                </option>
                            ))}
                        </select>
                    );
                }}
                renderTools={(isLoading, year, month) => {
                    return (
                        <>
                            <span>
                                <CustomerSupportButton />
                            </span>
                            <VacationModal
                                employeeId={selectedEmployee.employeeId}
                                trigger={
                                    <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                        Vacation Available: <strong>{selectedEmployee.vacationAvailable}</strong>
                                    </button>
                                }
                            />
                            <SickTimeModal
                                employeeId={selectedEmployee.employeeId}
                                trigger={
                                    <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                        Sick Time Available: <strong>{selectedEmployee.sickAvailable}</strong>
                                    </button>
                                }
                            />
                            <MonthlyStatusModal
                                year={year}
                                month={month}
                                trigger={
                                    <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                        Monthly Status
                                    </button>
                                }
                            />
                        </>
                    );
                }}
                renderButtons={(
                    handleSubmit,
                    isSubmitted,
                    isApproved,
                    isCalculated,
                    isValidWeek,
                    submittingMode,
                    isLoading,
                    weekFrom
                ) => {
                    if (isCalculated || !isValidWeek) {
                        return null;
                    }

                    const firstName = selectedEmployee.user.firstName;

                    return (
                        <>
                            <ClickButton
                                className="btn btn-sm btn-primary"
                                title="Save"
                                onClick={() => handleSubmit('my-employees-time.save')}
                                isLoading={submittingMode === 'my-employees-time.save'}
                                isDisabled={isLoading}
                            />
                            {!isSubmitted && (
                                <ClickButton
                                    className="btn btn-sm btn-primary"
                                    title="Submit"
                                    onClick={() => handleSubmit('my-employees-time.submit')}
                                    isLoading={submittingMode === 'my-employees-time.submit'}
                                    isDisabled={isLoading}
                                />
                            )}
                            {isSubmitted && !isApproved && (
                                <ClickButton
                                    className="btn btn-sm btn-primary"
                                    title="Approve"
                                    onClick={() => handleSubmit('my-employees-time.approve')}
                                    isLoading={submittingMode === 'my-employees-time.approve'}
                                    isDisabled={isLoading}
                                />
                            )}
                            {isSubmitted && !isApproved && (
                                <Modal
                                    trigger={
                                        <ClickButton
                                            className="btn btn-sm btn-primary"
                                            title="Reject"
                                            isLoading={submittingMode === 'my-employees-time.reject'}
                                            isDisabled={isLoading}
                                        />
                                    }
                                    title="Reject Message"
                                >
                                    <p>
                                        {firstName} will be sent an e-mail to indicate that his/her time data has been
                                        rejected. Please customize the contents of that e-mail below.
                                    </p>
                                    <Formik
                                        initialValues={{
                                            message: utils.rejectDefaultMsg(firstName, myFullName, weekFrom),
                                        }}
                                        render={({ values }) => (
                                            <Form>
                                                <Field name="message" component={CccisdTextarea} rows="10" />
                                                <ClickButton
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() => handleSubmit('my-employees-time.reject', values)}
                                                >
                                                    Send Reject
                                                </ClickButton>
                                            </Form>
                                        )}
                                    />
                                </Modal>
                            )}
                        </>
                    );
                }}
            />
        </>
    );
};
