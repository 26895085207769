import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import employeesQuery from './employees.graphql';
import Loader from 'cccisd-loader';
import Table from 'cccisd-table';

const Body = ({ year, month, orderBy }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getMonthlyStatus();
    }, []);

    const getMonthlyStatus = async () => {
        const response = await client.query({
            query: employeesQuery,
            fetchPolicy: 'network-only',
            variables: { year, month },
        });

        setData(
            response.data.timeTracker.employeeList.map(employee => {
                return {
                    employee: employee.user.fullName,
                    supervisor: employee.supervisor.user.fullName,
                    submitted: employee.submittedSummary.submitted || 0,
                    approved: employee.approvedSummary.approved || 0,
                };
            })
        );

        setIsLoading(false);
    };

    const columns = [
        {
            name: 'employee',
            label: 'Employee',
            filter: true,
        },
        {
            name: 'submitted',
            label: 'Submitted',
            sort: true,
        },
        {
            name: 'supervisor',
            label: 'Supervisor',
            filter: true,
        },
        {
            name: 'approved',
            label: 'Approved',
            sort: true,
        },
    ];

    return (
        <Loader type="inline" loading={isLoading} removeChildren>
            <Table
                name="admin-monthly-status"
                saveState={false}
                rowKey="employee"
                perPage={1000}
                showPerPageOptions={false}
                columns={columns}
                data={data}
                orderBy={orderBy}
                isAscentOrder
                hideShowingRowsSummary
            />
        </Loader>
    );
};

export default Body;
