import React from 'react';
import Header from 'cccisd-header';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const isPMDashboard = Fortress.hasAnyAccess('quest.view_identifiable_data', 224);
const isLoggedIn = Fortress.auth();

const AppHeader = () => {
    const primaryNavLinks = [
        { url: '/manage', label: 'Manage' },
        { url: '/timeTracker', label: 'Time Tracker' },
    ];

    const limitedViewLinks = [
        { url: '/Apps', label: 'Apps' },
        { url: '/Messaging', label: 'SMS' },
        { url: '/EmailMessaging', label: 'Email' },
        { url: '/ReleaseNotes', label: 'Release Notes' },
        { url: '/Packages', label: 'Packages' },
        { url: '/Usages', label: 'Usage' },
        { url: '/ClientSupportDashboard', label: 'Client Support Dashboard' },
        { url: '/Passwords', label: 'Passwords' },
    ];

    const pmDashboardLinks = [...limitedViewLinks, { url: '/PMDashboard', label: 'PM Dashboard' }];

    const makeSecondaryLinks = () => {
        if (isLoggedIn) {
            if (isPMDashboard) {
                return pmDashboardLinks;
            }
            return limitedViewLinks;
        }
        return [];
    };

    return (
        <>
            <Header
                containerClassName="container-fluid"
                logoClassName={style.logo}
                primaryNavLinks={primaryNavLinks}
                secondaryNavLinks={makeSecondaryLinks()}
                showAlertAction={false}
            />
            <div className={style.name}>3C Monitoring</div>
        </>
    );
};

export default AppHeader;
