const dayColumnsDefault = [
    { name: 'monday', label: 'Mon', date: null },
    { name: 'tuesday', label: 'Tue', date: null },
    { name: 'wednesday', label: 'Wed', date: null },
    { name: 'thursday', label: 'Thu', date: null },
    { name: 'friday', label: 'Fri', date: null },
    { name: 'saturday', label: 'Sat', date: null },
    { name: 'sunday', label: 'Sun', date: null },
];

const initialValuesDefault = { timeData: {} };

const initialValuesHash = initialValues => {
    return `${initialValues.year || 0}
        -${initialValues.month || 0}
        -${initialValues.week || 0}
        -${initialValues.employeeId || 0}
        -${Object.keys(initialValues.timeData).length}`;
};

const hoursThisWeek = formikProps => {
    return Object.values(formikProps.values.timeData).reduce((prev, curr) => {
        const hours = Object.values(curr).reduce((a, b) => {
            const f = parseFloat(b);
            return a + (Number.isNaN(f) || f < 0 ? 0 : f);
        }, 0);

        return prev + hours;
    }, 0);
};

const columnLabel = (label, date) => {
    if (!date) {
        return label;
    }

    return `${label} ${dateToLabel(date)}`;
};

const weekFrom = dayColumns => {
    const day = dayColumns.find(d => d.date);

    if (!day) {
        return null;
    }

    return dateToLabel(day.date);
};

const weekTo = dayColumns => {
    const day = dayColumns.findLast(d => d.date);

    if (!day) {
        return null;
    }

    return dateToLabel(day.date);
};

const dateToLabel = date => {
    const parts = date.split('-');

    return `${parts[1]}/${parts[2]}`;
};

export default {
    dayColumnsDefault,
    initialValuesDefault,
    initialValuesHash,
    hoursThisWeek,
    columnLabel,
    weekFrom,
    weekTo,
};
