import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import { ResponsiveLine } from 'cccisd-nivo/line';
import dropdown from './pm_dropdown.graphql';
import projectDataQuery from './projectData.graphql';
import style from './style.css';
import { differenceInDays, format, subDays } from 'date-fns';
import Callout from '../../components/Callout/index.js';
import { renderAlert } from '../dashboardHelpers.js';

import IconPlus from 'cccisd-icons/plus-circle';
import IconInfo from 'cccisd-icons/info';
import IconStats from 'cccisd-icons/stats-dots';
import IconFlag from 'cccisd-icons/flag';

const Fortress = window.cccisd.fortress;

const PMDashboard = () => {
    const username = Fortress.user.user.username;
    const [allProjects, setAllProjects] = useState([]);
    const [selectedProjectData, setSelectedProjectData] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [radioFilter, setRadioFilter] = useState('all');
    const [noProjects, setNoProjects] = useState(false);
    const [editMode, setEditMode] = useState({});

    async function getSelectedProjectData() {
        if (!selectedProject) {
            return;
        }

        const resp = await client.query({
            query: projectDataQuery,
            variables: { metricsPawnId: selectedProject.descendantRoles.metricspawn.pawn.pawnId },
            fetchPolicy: 'network-only',
        });

        setSelectedProjectData(resp.data.roles.metricspawn);
    }

    async function getProjectList() {
        let filter = null;
        if (radioFilter === 'mine') {
            filter = {
                eq: {
                    field: 'descendantRoles.metricspawn.assignmentProgress.devTags.projectManager',
                    string: Fortress.user.acting.user.username,
                },
            };
        }

        const data = await client.query({ query: dropdown, variables: { filter }, fetchPolicy: 'network-only' });

        if (radioFilter === 'flagged') {
            const projects = data.data.groups.projectList;

            if (projects.length > 0) {
                const flaggedProjects = projects.filter(
                    project => project.descendantRoles.metricspawn.flagged.devTags.isProjectFlagged === 'checked'
                );

                if (flaggedProjects.length === 0) {
                    return setNoProjects(true);
                }

                setAllProjects(flaggedProjects);
                setSelectedProject(flaggedProjects[0]);
            }
        }

        if (data.data.groups.projectList.length === 0 || allProjects.length === 0) {
            setNoProjects(true);
        }

        if (data.data.groups.projectList.length > 0 && radioFilter !== 'flagged') {
            setAllProjects(data.data.groups.projectList);
            const defaultProject = data.data.groups.projectList[0];
            setSelectedProject(defaultProject);
            setNoProjects(false);
        }
    }
    useEffect(() => {
        getProjectList();
    }, []);

    useEffect(() => {
        if (selectedProject) {
            getSelectedProjectData();
        }
    }, [selectedProject]);

    useEffect(() => {
        getProjectList();
    }, [radioFilter]);

    const mostRecentProjectUpdate =
        Array.isArray(selectedProjectData?.projectUpdate) &&
        selectedProjectData.projectUpdate.length > 0 &&
        selectedProjectData.projectUpdate[0];

    const allProjectUpdates = Array.isArray(selectedProjectData?.projectUpdate)
        ? selectedProjectData.projectUpdate
        : [];

    const completedProjectData = allProjectUpdates.filter(update => update.completedDate);

    const previous7days = [0, 1, 2, 3, 4, 5, 6].map(i => {
        const timeStamp = subDays(new Date(), i);

        return format(timeStamp, 'YYYY-MM-DD');
    });

    const renderProjectModals = () => {
        const {
            burnRate,
            completionRate,
            weeksOverDate,
            isProjectFlagged,
            timelineRating,
            timelineNotes,
            scopeRating,
            scopeNotes,
            moraleRating,
            moraleNotes,
            generalNotes,
        } = mostRecentProjectUpdate && mostRecentProjectUpdate.devTags;

        const editPreviousUpdate = () => {
            let deploymentToEdit = {};
            const completedInPrevious7Days = completedProjectData.filter(value =>
                previous7days.includes(value.completedDate)
            );

            if (completedInPrevious7Days.length > 0) {
                deploymentToEdit = completedInPrevious7Days[0];

                setEditMode({
                    completedDate: deploymentToEdit.completed,
                    deploymentId: deploymentToEdit.deploymentId,
                });
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <Modal
                        trigger={
                            <button type="button" className="btn btn-info" style={{ marginRight: '10px' }}>
                                <IconPlus spaceRight />
                                Project Info
                            </button>
                        }
                        title={selectedProject.group.label + ' - Info'}
                        size="large"
                    >
                        <DeploymentPlayer
                            deploymentHandle="project_info"
                            disableLayout
                            onComplete={() => getSelectedProjectData()}
                            pawnId={selectedProject.descendantRoles.metricspawn.pawn.pawnId}
                            pawnHash={selectedProject.descendantRoles.metricspawn.pawn.pawnHash}
                            flowProps={{
                                defaultValueVariables: {
                                    projectName: selectedProject.group.label,
                                    projectManager: username,
                                    startDate: {
                                        date: format(new Date(), 'YYYY-MM-DD'),
                                        startedAt: selectedProject.descendantRoles.metricspawn.startedAt,
                                    },
                                },
                            }}
                        />
                    </Modal>
                </div>
                <div>
                    <Modal
                        trigger={
                            <button type="button" className="btn btn-primary">
                                <IconStats spaceRight />
                                Project Update
                            </button>
                        }
                        title={
                            editMode.completedDate
                                ? `Editing ${selectedProject.group.label} Update from ${editMode.completedDate}`
                                : selectedProject.group.label + ' - Update'
                        }
                        size="large"
                        beforeShow={() => editPreviousUpdate()}
                        headerStyle={editMode.completedDate ? { backgroundColor: '#449D44', color: '#fff' } : {}}
                    >
                        <DeploymentPlayer
                            deploymentHandle="project_update"
                            disableLayout
                            deploymentId={editMode ? editMode.deploymentId : null}
                            onComplete={() => getSelectedProjectData()}
                            pawnId={selectedProject.descendantRoles.metricspawn.pawn.pawnId}
                            pawnHash={selectedProject.descendantRoles.metricspawn.pawn.pawnHash}
                            flowProps={{
                                defaultValueVariables: {
                                    burnRate,
                                    completionRate,
                                    isProjectFlagged,
                                    timelineRating,
                                    timelineNotes,
                                    scopeRating,
                                    scopeNotes,
                                    moraleRating,
                                    moraleNotes,
                                    generalNotes,
                                    weeksOverDate,
                                },
                            }}
                        />
                    </Modal>
                </div>
            </div>
        );
    };

    const renderProjectDropdown = () => (
        <>
            <form className="form-inline">
                <div className={'form-group ' + style.projectDropdownForm}>
                    <label htmlFor="site">Project</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select
                            value={selectedProject.group.groupId}
                            className={'form-control ' + style.field}
                            id="site"
                            onChange={e => {
                                let desiredProject = allProjects.find(project => {
                                    return Number(project.group.groupId) === Number(e.target.value);
                                });
                                setSelectedProject(desiredProject);
                            }}
                        >
                            {allProjects.map(project => (
                                <option value={project.group.groupId} key={project.group.groupId}>
                                    {project.group.label}
                                </option>
                            ))}
                        </select>
                        <div className={style.field}>
                            <input
                                type="radio"
                                name="all"
                                id="all"
                                value="all"
                                checked={radioFilter === 'all'}
                                onChange={e => setRadioFilter('all')}
                            />
                            <label htmlFor="all">All Projects</label>
                        </div>
                        <div className={style.field}>
                            <input
                                type="radio"
                                name="mine"
                                id="mine"
                                value="mine"
                                checked={radioFilter === 'mine'}
                                onChange={e => setRadioFilter('mine')}
                            />
                            <label htmlFor="mine">My Projects</label>
                        </div>
                        <div className={style.field}>
                            <input
                                type="radio"
                                name="flagged"
                                value="flagged"
                                id="flagged"
                                checked={radioFilter === 'flagged'}
                                onChange={e => setRadioFilter('flagged')}
                            />
                            <label htmlFor="flagged">Flagged Projects</label>
                        </div>
                    </div>
                </div>
            </form>
            <hr style={{ borderColor: '#e17d26' }} />
        </>
    );

    function getBackgroundColor(val) {
        if (Number.isNaN(Number(val))) {
            return '#cc3232';
        }
        if (Number(val) <= 1) {
            return '#cc3232';
        }
        if (Number(val) <= 2) {
            return '#e7b416';
        }
        return '#99c140';
    }

    const renderProjectInfo = () => {
        if (!selectedProject?.descendantRoles?.metricspawn?.pawn?.pawnId) {
            return <span />;
        }

        if (!selectedProjectData) {
            return <Loader loading type="inline" />;
        }

        const projectLength = differenceInDays(
            selectedProjectData.projectInfo?.devTags?.projectedEndDate || '',
            selectedProjectData.projectInfo?.devTags?.startDate || ''
        );

        const isFlagged = mostRecentProjectUpdate?.devTags?.isProjectFlagged === 'checked';

        const formatBudget = () => {
            const devTagBudget = _get(selectedProjectData, 'projectInfo.devTags.budget') || '-';

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
            });

            return devTagBudget !== '-' ? formatter.format(devTagBudget) : '-';
        };

        const stats = [
            {
                id: 'Timeline',
                notes: _get(mostRecentProjectUpdate, 'devTags.timelineNotes') || '-',
                rating: (
                    <div
                        className={style.snapshotBubble}
                        style={{
                            backgroundColor: getBackgroundColor(
                                _get(mostRecentProjectUpdate, 'devTags.timelineRating')
                            ),
                        }}
                    />
                ),
            },
            {
                id: 'Scope',
                notes: _get(mostRecentProjectUpdate, 'devTags.scopeNotes') || '-',
                rating: (
                    <div
                        className={style.snapshotBubble}
                        style={{
                            backgroundColor: getBackgroundColor(_get(mostRecentProjectUpdate, 'devTags.scopeRating')),
                        }}
                    />
                ),
            },
            {
                id: 'Morale',
                notes: _get(mostRecentProjectUpdate, 'devTags.moraleNotes') || '-',
                rating: (
                    <div
                        className={style.snapshotBubble}
                        style={{
                            backgroundColor: getBackgroundColor(_get(mostRecentProjectUpdate, 'devTags.moraleRating')),
                        }}
                    />
                ),
            },
        ];

        return (
            <div>
                <div className={style.projectDetails}>
                    <h2>
                        {selectedProject.group.label}{' '}
                        {isFlagged && (
                            <span style={{ color: '#cc3232' }}>
                                <IconFlag />
                            </span>
                        )}
                    </h2>
                    <div>{renderProjectModals()}</div>
                </div>
                {mostRecentProjectUpdate ? (
                    <div className={style.projectSnapshot}>
                        <div className={` ${style.details}`}>
                            <div>
                                <div className={style.projectDescription}>
                                    <b>Start Date</b>{' '}
                                    {selectedProjectData?.projectInfo?.devTags?.projectDescription && (
                                        <Tooltip
                                            title={
                                                _get(selectedProjectData, 'projectInfo.devTags.projectDescription') ||
                                                '-'
                                            }
                                        >
                                            <IconInfo spaceRight />
                                        </Tooltip>
                                    )}
                                </div>
                                <div>{_get(selectedProjectData, 'projectInfo.startDate') || '-'}</div>
                            </div>
                            <div>
                                <b>Projected End Date</b>
                                <div>{_get(selectedProjectData, 'projectInfo.projectedEndDate') || '-'}</div>
                            </div>
                            <div>
                                <b>Contractual End Date</b>
                                <div>{_get(selectedProjectData, 'projectInfo.contractualEndDate') || '-'}</div>
                            </div>
                            <div style={{ overflowWrap: 'break-word' }}>
                                <b>Project Manager</b>
                                <div>{_get(selectedProjectData, 'projectInfo.devTags.projectManager') || '-'}</div>
                            </div>
                            <div>
                                <b>Completion Rate</b>
                                <div>{_get(mostRecentProjectUpdate, 'devTags.completionRate') || '-'}%</div>
                            </div>
                        </div>
                        <div className={style.callouts}>
                            {stats.map(stat => {
                                return (
                                    <div className="col-sm-4" key={stat.id}>
                                        <Callout id={stat.id} notes={stat.notes} rating={stat.rating} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    renderAlert(' Not enough Project Info to show - please complete both surveys.')
                )}
                {mostRecentProjectUpdate &&
                selectedProjectData?.projectInfo?.completedDate &&
                selectedProjectData?.projectInfo?.devTags?.projectedEndDate ? (
                    <>
                        <div className={style.graphContainer}>
                            <div style={{ textAlign: 'center' }}>
                                <h3>Operating Budget</h3>
                                <h4>{formatBudget()}</h4>
                            </div>
                            <ResponsiveLine
                                animate
                                axisBottom={{
                                    format: projectLength < 365 ? '%b %d' : '%b %Y',
                                    legend: 'Project Timeline',
                                    legendOffset: 50,
                                    legendPosition: 'middle',
                                    tickRotation: -30,
                                }}
                                axisLeft={{
                                    legend: 'Burn Rate (%)',
                                    legendOffset: -50,
                                    legendPosition: 'middle',
                                }}
                                curve="monotoneX"
                                data={[
                                    {
                                        id: 'expected',
                                        data: [
                                            {
                                                x: selectedProjectData.projectInfo?.devTags?.startDate || '',
                                                y: 0,
                                            },
                                            {
                                                x: selectedProjectData.projectInfo?.devTags?.projectedEndDate || '',
                                                y: 100,
                                            },
                                        ],
                                    },
                                    {
                                        id: 'actual',
                                        data: completedProjectData.map(d => {
                                            return {
                                                x: d.completedDate,
                                                y: Number(d.devTags.burnRate),
                                            };
                                        }),
                                    },
                                ]}
                                enablePointLabel
                                height={400}
                                margin={{
                                    bottom: 60,
                                    left: 60,
                                    right: 20,
                                    top: 20,
                                }}
                                markers={[
                                    {
                                        axis: 'y',
                                        value: '100',
                                        lineStyle: {
                                            stroke: 'green',
                                            strokeWidth: 1,
                                        },
                                    },
                                ]}
                                pointBorderColor={{
                                    from: 'color',
                                    modifiers: [['darker', 0.3]],
                                }}
                                pointBorderWidth={1}
                                pointSize={16}
                                useMesh
                                width={900}
                                xFormat="time:%m/%d/%Y"
                                yFormat={d => `Burn Rate ${d}%`}
                                xScale={{
                                    format: '%Y-%m-%d',
                                    precision: 'day',
                                    type: 'time',
                                    useUTC: false,
                                }}
                                yScale={{
                                    type: 'linear',
                                }}
                                colors={{ scheme: 'paired' }}
                            />
                        </div>
                        <div className={style.generalBox}>
                            <div className={style.generalHeader}>
                                <h3>General Notes</h3>
                                <div style={{ color: '#ccc' }}>{_get(mostRecentProjectUpdate, 'completed') || '-'}</div>
                            </div>
                            <hr />
                            {_get(mostRecentProjectUpdate, 'devTags.generalNotes') || '-'}
                        </div>
                    </>
                ) : (
                    renderAlert('Please add required fields to Project Info.')
                )}
            </div>
        );
    };

    if (!selectedProject?.group?.groupId) {
        return renderAlert('No matching projects found.');
    }

    return (
        <div>
            {renderProjectDropdown()}
            {allProjects.length > 0 && !noProjects ? renderProjectInfo() : renderAlert('No matching projects found.')}
        </div>
    );
};

export default PMDashboard;
