import React, { useRef } from 'react';
import _pick from 'lodash/pick';
import style from './style.css';

export default props => {
    const fileInputRef = useRef();

    const handleChooseFile = () => {
        fileInputRef.current.click();
    };

    const { field, form } = props;

    const passingProps = _pick(props, ['autoFocus', 'autoComplete', 'type', 'style', 'id', 'disabled']);
    const passingField = field;

    const error = form.errors[field.name];

    return (
        <div className={style.wrapper + ' ' + (error ? 'form-group has-error' : '')}>
            <input
                ref={fileInputRef}
                type="file"
                accept=".csv"
                {...passingField}
                {...passingProps}
                value=""
                style={{ display: 'none' }}
                onChange={e => {
                    form.setFieldValue(field.name, e.currentTarget.files[0]);
                }}
            />
            <div className={style.fileField}>
                <button type="button" className="btn btn-primary btn-sm" onClick={handleChooseFile}>
                    {field.value ? 'Change file' : 'Choose file'}
                </button>
                {field.value && <span>{field.value.name}</span>}
            </div>
            {error && <div className="help-block">{error}</div>}
        </div>
    );
};
