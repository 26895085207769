import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import Table from 'cccisd-table';
import PropTypes from 'prop-types';

import tableQuery from './table.graphql';
import { getMonthOfDate, julyToDec, janToJune, semesterTotal, thisMonth, yearSettings } from '../../dashboardHelpers';

const CalendarYearClients = props => {
    const [tableRows, setTableRows] = useState([]);

    const {
        tableName,
        tableFilter: { filter },
    } = props;

    useEffect(() => {
        (async () => {
            try {
                await client
                    .query({
                        query: tableQuery,
                        fetchPolicy: 'network-only',
                        variables: { filter },
                    })
                    .then(response => {
                        const orgNames = response.data.groups.organizationList.map(org => org.variableName);
                        const clients = response.data.flows.assignmentProgressList;

                        const tableClients = clients.filter(cl => orgNames.includes(cl.devTags.clientName));

                        const getCalendarYearTotal = (columnYear, clientName) => {
                            const byClient = tableClients.filter(
                                tableClient =>
                                    tableClient.devTags.clientName === clientName &&
                                    tableClient.devTags.supportDate.includes(columnYear)
                            );

                            if (byClient.length > 0) {
                                const yearTotal = byClient
                                    .map(tableClient => +tableClient.devTags.supportMinutes)
                                    .reduce((a, b) => a + b);

                                const inHours = (yearTotal / 60).toFixed(2);

                                return inHours;
                            }

                            return 0;
                        };

                        const calendarYear = response.data.groups.organizationList.map(org => {
                            return {
                                id: org.group.groupId,
                                label: org.group.label,
                                url: org.mainUrl,
                                threeYearsAgo: getCalendarYearTotal(
                                    yearSettings.calendar.threeYearsAgo,
                                    org.variableName
                                ),
                                twoYearsAgo: getCalendarYearTotal(yearSettings.calendar.twoYearsAgo, org.variableName),
                                oneYearAgo: getCalendarYearTotal(yearSettings.calendar.oneYearAgo, org.variableName),
                                currentYear: getCalendarYearTotal(yearSettings.calendar.currentYear, org.variableName),
                            };
                        });

                        const getMidYearTotal = (fallSemesterYear, springSemesterYear, clientName) => {
                            const fallYear = tableClients.filter(
                                tableClient =>
                                    tableClient.devTags.clientName === clientName &&
                                    tableClient.devTags.supportDate.includes(fallSemesterYear)
                            );

                            const springYear = tableClients.filter(
                                tableClient =>
                                    tableClient.devTags.clientName === clientName &&
                                    tableClient.devTags.supportDate.includes(springSemesterYear)
                            );

                            const fallMonths =
                                fallYear.length > 0
                                    ? fallYear.filter(flow =>
                                          julyToDec.includes(getMonthOfDate(flow.devTags.supportDate))
                                      )
                                    : false;

                            const springMonths =
                                springYear.length > 0
                                    ? springYear.filter(flow =>
                                          janToJune.includes(getMonthOfDate(flow.devTags.supportDate))
                                      )
                                    : false;

                            const fallTotal = fallMonths && fallMonths.length > 0 ? semesterTotal(fallMonths) : 0;
                            const springTotal =
                                springMonths && springMonths.length > 0 ? semesterTotal(springMonths) : 0;

                            const midYearTotal = (fallTotal + springTotal) / 60;

                            return midYearTotal > 0 ? midYearTotal.toFixed(2) : 0;
                        };

                        const midYear = response.data.groups.organizationList.map(org => {
                            return {
                                id: org.group.groupId,
                                label: org.group.label,
                                url: org.mainUrl,
                                twoYearsAgo: getMidYearTotal(
                                    yearSettings.midYear.twoYearsAgo.fallSemesterYear,
                                    yearSettings.midYear.twoYearsAgo.springSemesterYear,
                                    org.variableName
                                ),
                                oneYearAgo: getMidYearTotal(
                                    yearSettings.midYear.oneYearAgo.fallSemesterYear,
                                    yearSettings.midYear.oneYearAgo.springSemesterYear,
                                    org.variableName
                                ),
                                currentYear: getMidYearTotal(
                                    yearSettings.midYear.currentYear.fallSemesterYear,
                                    yearSettings.midYear.currentYear.springSemesterYear,
                                    org.variableName
                                ),
                            };
                        });

                        setTableRows(tableName === 'Calendar Year Clients' ? calendarYear : midYear);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [filter]);

    const renderColumnsAccordingToCurrentMonth = () => {
        const label = { name: 'label', label: 'App Name', filter: true, sort: true };
        const url = {
            name: 'url',
            label: 'Production URL',
            render: value => (
                <a href={value} target="_blank" rel="noopener noreferrer">
                    {value}
                </a>
            ),
            filter: true,
            sort: true,
        };

        if (thisMonth <= 6 && tableName === 'Calendar Year Clients') {
            return [
                label,
                url,
                {
                    name: 'twoYearsAgo',
                    label: yearSettings.calendar.twoYearsAgo + ' Hours',
                    filter: true,
                    sort: true,
                    class: 'text-center',
                },
                {
                    name: 'oneYearAgo',
                    label: yearSettings.calendar.oneYearAgo + ' Hours',
                    filter: true,
                    sort: true,
                    class: 'text-center',
                },
                {
                    name: 'currentYear',
                    label: yearSettings.calendar.currentYear + ' Hours',
                    filter: true,
                    sort: true,
                    class: 'text-center',
                    setClass: () => 'bg-warning',
                },
            ];
        }
        if (thisMonth <= 6 && tableName === 'Mid-Year Clients') {
            return [
                label,
                url,
                {
                    name: 'twoYearsAgo',
                    label: yearSettings.midYear.threeYearsAgo.columnHeader,
                    filter: true,
                    sort: true,
                    class: 'text-center',
                },
                {
                    name: 'oneYearAgo',
                    label: yearSettings.midYear.twoYearsAgo.columnHeader,
                    filter: true,
                    sort: true,
                    class: 'text-center',
                },
                {
                    name: 'currentYear',
                    label: yearSettings.midYear.oneYearAgo.columnHeader,
                    filter: true,
                    sort: true,
                    class: 'text-center',
                    setClass: () => 'bg-warning',
                },
            ];
        }
        if (thisMonth > 6 && tableName === 'Calendar Year Clients') {
            return [
                label,
                url,
                {
                    name: 'twoYearsAgo',
                    label: yearSettings.calendar.twoYearsAgo + ' Hours',
                    filter: true,
                    sort: true,
                    class: 'text-center',
                },
                {
                    name: 'oneYearAgo',
                    label: yearSettings.calendar.oneYearAgo + ' Hours',
                    filter: true,
                    sort: true,
                    class: 'text-center',
                    setClass: () => (thisMonth <= 6 ? 'bg-warning' : ''),
                },
                {
                    name: 'currentYear',
                    label: yearSettings.calendar.currentYear + ' Hours',
                    filter: true,
                    sort: true,
                    class: 'text-center',
                    setClass: () => 'bg-warning',
                },
            ];
        }

        if (thisMonth > 6 && tableName === 'Mid-Year Clients') {
            return [
                label,
                url,
                {
                    name: 'twoYearsAgo',
                    label: yearSettings.midYear.twoYearsAgo.columnHeader,
                    filter: true,
                    sort: true,
                    class: 'text-center',
                },
                {
                    name: 'oneYearAgo',
                    label: yearSettings.midYear.oneYearAgo.columnHeader,
                    filter: true,
                    sort: true,
                    class: 'text-center',
                },
                {
                    name: 'currentYear',
                    label: yearSettings.midYear.currentYear.columnHeader,
                    filter: true,
                    sort: true,
                    class: 'text-center',
                    setClass: () => 'bg-warning',
                },
            ];
        }
    };

    return (
        <>
            <h2>{tableName}</h2>
            <Table
                columns={renderColumnsAccordingToCurrentMonth()}
                data={tableRows}
                isCsvDownload
                hideShowingRowsSummary
                showPerPageOptions={false}
                rowKey="id"
                csvFilename={`${tableName}.csv`}
            />
        </>
    );
};

CalendarYearClients.propTypes = {
    tableFilter: PropTypes.object,
    tableName: PropTypes.string,
};

export default CalendarYearClients;
