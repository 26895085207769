import React, { useRef } from 'react';
import Body from './body';
import Modal from 'cccisd-modal';

export default props => {
    const ptoSettingsModal = useRef();

    return (
        <Modal ref={ptoSettingsModal} trigger={props.trigger} title="PTO Settings" size="large">
            <Body
                {...props}
                callback={() => {
                    ptoSettingsModal.current.close();
                }}
            />
        </Modal>
    );
};
