import React from 'react';
import _pick from 'lodash/pick';
import IconNotification from 'cccisd-icons/notification2';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

export default props => {
    const { field, form } = props;

    const passingProps = _pick(props, ['autoFocus', 'autoComplete', 'type', 'style', 'id', 'disabled']);
    const passingField = { ...field };

    const error = form.errors[field.name];

    return (
        <div className={style.wrapper + ' ' + (error ? style.hasError : '')}>
            {error && (
                <div className={style.error}>
                    <Tooltip type="error" placement="right" title={error}>
                        <IconNotification />
                    </Tooltip>
                </div>
            )}
            <input className="form-control" type="number" min="0" max="24" {...passingField} {...passingProps} />
        </div>
    );
};
