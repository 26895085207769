import React from 'react';
import commonUtils from '../../utils';
import style from './style.css';

const table = (timeAdminList, projectList, year, month) => {
    let totalOverall = 0;
    const totalColumn = { day: 'Total' };

    const days = Array.from({
        length: new Date(year, month, 0).getDate(),
    }).map((_, i) => ({ day: i + 1, total: 0 }));

    timeAdminList.forEach(time => {
        const day = time.workDate.split('-');
        const index = Number.parseInt(day[2], 10) - 1;
        const columnName = `project-${time.projectId}`;

        totalOverall = fixFloat(totalOverall + time.time);
        totalColumn[columnName] = fixFloat((totalColumn[columnName] || 0) + time.time);

        days[index] = {
            ...days[index],
            [columnName]: fixFloat(time.time),
            total: fixFloat(days[index].total + time.time),
        };
    });

    days.push({ ...totalColumn, total: fixFloat(totalOverall) });

    const projectIds = timeAdminList.map(t => t.projectId);
    const reportedProjectList = projectList.filter(p => projectIds.includes(p.group.groupId));

    const columns = [
        {
            name: 'day',
            label: 'Date',
            render: value => (value === 'Total' ? value : `${commonUtils.monthToShortName(month)} ${value}`),
        },
        ...reportedProjectList.map(project => {
            return {
                name: `project-${project.group.groupId}`,
                label: project.group.label,
                render: (value, row) => (row.day === 'Total' ? <strong>{value}</strong> : value),
            };
        }),
        {
            name: 'total',
            label: 'Total',
            class: style.total,
        },
    ];

    return {
        data: days,
        columns,
    };
};

const fixFloat = value => Number.parseFloat(value.toFixed(2));

export default {
    table,
};
