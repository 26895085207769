import React, { useRef } from 'react';
import Body from './body';
import Modal from 'cccisd-modal';
import utils from '../../utils';

export default props => {
    const adjustModal = useRef();

    return (
        <Modal
            ref={adjustModal}
            trigger={props.trigger}
            title={`Adjust Timesheets - ${utils.monthToName(props.month)} ${props.year}`}
        >
            <Body
                {...props}
                callback={() => {
                    props.callback();
                    adjustModal.current.close();
                }}
            />
        </Modal>
    );
};
