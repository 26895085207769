import React from 'react';
import Table from 'cccisd-table';
import utils from '../../utils';
import style from './style.css';

export default ({ year, user, vacationAvailable, vacationAccrual, vacationCap, vacationCarryover, vacationList }) => {
    const columns = [
        {
            name: 'month',
            label: 'Month',
            class: 'text-center',
            setClass: () => 'text-center',
            render: value => {
                return utils.monthToName(value);
            },
        },
        {
            name: 'vacationAccrued',
            label: 'Accrued',
            class: 'text-center',
            setClass: () => 'text-center',
        },
        {
            name: 'vacationUsed',
            label: 'Used',
            class: 'text-center',
            setClass: () => 'text-center',
        },
        {
            name: 'vacationAvailable',
            label: 'Available',
            class: 'text-center',
            setClass: () => 'text-center',
        },
    ];

    return (
        <>
            <h4>
                <strong>{user?.fullName}</strong> - {year}
            </h4>
            <div className={style.summary}>
                <div>
                    Available: <strong>{vacationAvailable}</strong>
                </div>
                <div>
                    Accrual per month: <strong>{vacationAccrual}</strong>
                </div>
                <div>
                    Cap: <strong>{vacationCap}</strong>
                </div>
                <div>
                    {year - 1} Carryover: <strong>{vacationCarryover || 0}</strong>
                </div>
            </div>
            <Table
                name="vacation-report"
                saveState={false}
                rowKey="month"
                perPage={1000}
                showPerPageOptions={false}
                columns={columns}
                data={vacationList}
                hideShowingRowsSummary
            />
        </>
    );
};
