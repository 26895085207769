import React from 'react';
import Body from './body';
import Modal from 'cccisd-modal';

export default props => {
    let modal = null;

    const close = () => {
        modal.close();
    };

    return (
        <Modal
            ref={el => {
                modal = el;
            }}
            trigger={props.trigger}
            title="Manage projects visibility"
            size="large"
        >
            <Body {...props} close={close} />
        </Modal>
    );
};
