import React from 'react';
import ClickButton from 'cccisd-click-button';
import confirm from 'cccisd-confirm';
import CustomerSupportButton from '../common/CustomerSupportButton';
import EmployeeTime from '../common/EmployeeTime';
import MonthlyStatusModal from '../common/MonthlyStatusModal';
import myTimeQuery from './myTime.graphql';
import ProjectsVisibilityModal from '../common/ProjectsVisibilityModal';
import SickTimeModal from '../common/SickTimeModal';
import VacationModal from '../common/VacationModal';

export default ({ sickAvailable, vacationAvailable, myFullName }) => (
    <EmployeeTime
        self
        timeQuery={myTimeQuery}
        employeeId={undefined}
        renderHeader={isLoading => {
            return <h1>{myFullName}</h1>;
        }}
        renderTools={(isLoading, year, month, refresh) => {
            return (
                <>
                    <span>
                        <CustomerSupportButton />
                    </span>
                    <span>
                        <button
                            type="button"
                            className="btn btn-sm btn-info"
                            disabled={isLoading}
                            onClick={window.print}
                        >
                            Print
                        </button>
                    </span>
                    <ProjectsVisibilityModal
                        callback={refresh}
                        trigger={
                            <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                Projects
                            </button>
                        }
                    />
                    <VacationModal
                        self
                        trigger={
                            <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                Vacation Available: <strong>{vacationAvailable}</strong>
                            </button>
                        }
                    />
                    <SickTimeModal
                        self
                        trigger={
                            <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                Sick Time Available: <strong>{sickAvailable}</strong>
                            </button>
                        }
                    />
                    <MonthlyStatusModal
                        self
                        year={year}
                        month={month}
                        trigger={
                            <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                Monthly Status
                            </button>
                        }
                    />
                </>
            );
        }}
        renderButtons={(
            handleSubmit,
            isSubmitted,
            isApproved,
            isCalculated,
            isValidWeek,
            submittingMode,
            isLoading
        ) => {
            if (isSubmitted || isCalculated || !isValidWeek) {
                return null;
            }

            return (
                <>
                    <ClickButton
                        className="btn btn-sm btn-primary"
                        title="Save"
                        onClick={() => handleSubmit('my-time.save')}
                        isLoading={submittingMode === 'my-time.save'}
                        isDisabled={isLoading}
                    />
                    <ClickButton
                        className="btn btn-sm btn-primary"
                        title="Submit"
                        onClick={() => {
                            confirm({
                                message: `Confirm Submit`,
                                description: `You are submitting all hours for this week
                                              and only your supervisor can change submitted data.
                                              Do you still want to submit?`,
                                confirmLabel: 'Submit',
                                abortLabel: 'Cancel',
                            }).then(() => {
                                handleSubmit('my-time.submit');
                            });
                        }}
                        isLoading={submittingMode === 'my-time.submit'}
                        isDisabled={isLoading}
                    />
                </>
            );
        }}
    />
);
