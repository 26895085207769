import React, { useEffect, useState } from 'react';
import AdminDashboard from './AdminDashboard';
import { client } from 'cccisd-apollo';
import EmployeeManagement from './EmployeeManagement';
import Loader from 'cccisd-loader';
import MyEmployeesTime from './MyEmployeesTime';
import MyTime from './MyTime';
import ProjectData from './ProjectData';
import selfEmployeeQuery from './selfEmployee.graphql';
import Tabs from 'cccisd-tabs';
import { useLocation } from 'react-router-dom';

const Fortress = window.cccisd.fortress;

const TAB_MY_TIME = 'my-time-sheet';
const TAB_MY_EMPLOYEES = 'my-employees';
const TAB_PROJECT_DATA = 'project-data';
const TAB_ADMIN_DASHBOARD = 'admin-dashboard';
const TAB_EMPLOYEE_MANAGEMENT = 'employee-management';

export default () => {
    const urlSearchParams = new URLSearchParams(useLocation().search);
    const initialTab = urlSearchParams.get('tab') || TAB_MY_TIME;

    const [isLoading, setIsLoading] = useState(true);
    const [selfEmployeeExists, setSelfEmployeeExists] = useState(false);
    const [myEmployees, setMyEmployees] = useState([]);
    const [sickAvailable, setSickAvailable] = useState(0);
    const [vacationAvailable, setVacationAvailable] = useState(0);
    const [fullName, setFullName] = useState('');

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            const response = await client.query({
                query: selfEmployeeQuery,
                fetchPolicy: 'network-only',
            });

            setSelfEmployeeExists(!!response.data.timeTracker.selfEmployee.employeeId);
            setMyEmployees(response.data.timeTracker.selfEmployee.myEmployeeList);
            setSickAvailable(response.data.timeTracker.selfEmployee.sickAvailable || 0);
            setVacationAvailable(response.data.timeTracker.selfEmployee.vacationAvailable || 0);
            setFullName(response.data.timeTracker.selfEmployee.user.fullName || '');
            setIsLoading(false);
        })();
    }, []);

    const tabList = [
        {
            name: TAB_MY_TIME,
            title: 'My Time Sheet',
            content: (
                <MyTime sickAvailable={sickAvailable} vacationAvailable={vacationAvailable} myFullName={fullName} />
            ),
        },
    ];

    if (myEmployees.length) {
        tabList.push({
            name: TAB_MY_EMPLOYEES,
            title: 'My Employees',
            content: <MyEmployeesTime myEmployees={myEmployees} myFullName={fullName} />,
        });
    }

    if (Fortress.hasAccess('tracker.admin') || Fortress.hasAnyAccess('quest.view_identifiable_data', 224)) {
        tabList.push({
            name: TAB_PROJECT_DATA,
            title: 'Project Data',
            content: <ProjectData />,
        });
    }

    if (Fortress.hasAccess('tracker.admin')) {
        tabList.push({
            name: TAB_EMPLOYEE_MANAGEMENT,
            title: 'Employee Management',
            content: <EmployeeManagement />,
        });
    }

    if (Fortress.hasAccess('tracker.admin')) {
        tabList.push({
            name: TAB_ADMIN_DASHBOARD,
            title: 'Admin Dashboard',
            content: <AdminDashboard />,
        });
    }

    return (
        <Loader loading={isLoading} removeChildren>
            {selfEmployeeExists && <Tabs tabList={tabList} initialTab={initialTab} />}
            {!selfEmployeeExists && (
                <div className="alert-warning" style={{ padding: '25px' }}>
                    To use Time Tracker you must be set up as an employee. Ask system administrator for help.
                </div>
            )}
        </Loader>
    );
};
