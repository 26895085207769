import React, { useEffect, useState } from 'react';
import { CccisdDatepicker } from 'cccisd-formik';
import { client } from 'cccisd-apollo';
import byMonthQuery from './byMonth.graphql';
import byYearQuery from './byYear.graphql';
import Loader from 'cccisd-loader';
import projectsQuery from './projects.graphql';
import style from './style.css';
import Table from 'cccisd-table';
import utils from './utils';

const YEAR = 'year';
const MONTH = 'month';
const START_DATE = 'startDate';
const END_DATE = 'endDate';

const queries = {
    [YEAR]: byYearQuery,
    [MONTH]: byMonthQuery,
};

export default () => {
    const [isLoadingProjects, setIsLoadingProjects] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedGroupBy, setSelectedGroupBy] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [selectedStartDateInput, setSelectedStartDateInput] = useState(null);
    const [selectedEndDateInput, setSelectedEndDateInput] = useState(null);
    const [columns, setColumns] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        loadProjectList();
    }, []);

    useEffect(() => {
        loadData();
    }, [selectedGroupBy, selectedProjectId, selectedStartDate, selectedEndDate]);

    const loadProjectList = async () => {
        const response = await client.query({
            query: projectsQuery,
            fetchPolicy: 'network-only',
        });

        setProjects(response.data.groups.projectList);
        setSelectedProjectId(response.data.groups.projectList[0].group.groupId);
        setSelectedGroupBy(YEAR);
        setIsLoadingProjects(false);
    };

    const loadData = async () => {
        setIsLoadingData(true);

        const filter = [];
        if (selectedStartDate) {
            filter.push({ gte: { field: 'workDate', string: selectedStartDate } });
        }
        if (selectedEndDate) {
            filter.push({ lte: { field: 'workDate', string: selectedEndDate } });
        }

        const response = await client.query({
            query: queries[selectedGroupBy],
            fetchPolicy: 'network-only',
            variables: {
                projectId: selectedProjectId,
                filter: filter.length ? { AND: filter } : null,
            },
        });

        const table = utils.table(response.data.timeTracker.employeeList, selectedGroupBy === YEAR);

        setData(table.data);
        setColumns([
            {
                name: 'employee',
                label: 'Employee',
                filter: true,
            },
            ...Object.values(table.columns)
                .sort()
                .map(column => {
                    return {
                        name: column.toString(),
                        label: utils.header(column.toString(), selectedGroupBy === YEAR),
                    };
                }),
            {
                name: 'total',
                label: 'Total',
            },
        ]);

        setIsLoadingData(false);
    };

    const selectedProjectName = projects.find(project => project.group.groupId === selectedProjectId)?.group.label;

    const mockForm = {
        errors: {},
        setFieldValue: (name, value, reload = false) => {
            if (name === START_DATE) {
                setSelectedStartDateInput(value);
                reload && setSelectedStartDate(value);
            }

            if (name === END_DATE) {
                setSelectedEndDateInput(value);
                reload && setSelectedEndDate(value);
            }
        },
    };

    return (
        <Loader loading={isLoadingProjects} removeChildren>
            <section className={style.header}>
                <div className="row">
                    <div className="col-xs-8">
                        <div className={`${style.search} form-group`}>
                            <span>Project</span>
                            <select
                                className="form-control"
                                value={selectedProjectId}
                                onChange={event => setSelectedProjectId(Number.parseInt(event.target.value, 10))}
                                disabled={isLoadingData}
                            >
                                {projects.map(project => (
                                    <option key={project.group.groupId} value={project.group.groupId}>
                                        {project.group.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <div className={style.search}>
                            <div className="form-group">
                                <span>Start Date</span>
                            </div>
                            <CccisdDatepicker
                                field={{ value: selectedStartDateInput, name: START_DATE }}
                                form={mockForm}
                                reactDatepickerProps={{
                                    disabled: isLoadingData,
                                    onBlur: event => {
                                        mockForm.setFieldValue(
                                            START_DATE,
                                            utils.resolveValidDate(event.target.value),
                                            true
                                        );
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-8">
                        <div className={`${style.search} form-group`}>
                            <span>Show Columns As</span>
                            <select
                                className="form-control"
                                value={selectedGroupBy}
                                onChange={event => setSelectedGroupBy(event.target.value)}
                                disabled={isLoadingData}
                            >
                                {[
                                    { value: YEAR, label: 'Years' },
                                    { value: MONTH, label: 'Months' },
                                ].map(groupBy => (
                                    <option key={groupBy.value} value={groupBy.value}>
                                        {groupBy.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <div className={style.search}>
                            <div className="form-group">
                                <span>End Date</span>
                            </div>
                            <CccisdDatepicker
                                field={{ value: selectedEndDateInput, name: END_DATE }}
                                form={mockForm}
                                reactDatepickerProps={{
                                    disabled: isLoadingData,
                                    onBlur: event => {
                                        mockForm.setFieldValue(
                                            END_DATE,
                                            utils.resolveValidDate(event.target.value),
                                            true
                                        );
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className={style.projectDataTable}>
                <Table
                    name="project-data-table"
                    saveState={false}
                    rowKey="employee"
                    perPage={1000}
                    showPerPageOptions={false}
                    columns={columns}
                    data={data}
                    hideShowingRowsSummary
                    isCsvDownload={!isLoadingData}
                    csvButtonTitle={selectedProjectName ? `Download ${selectedProjectName} CSV` : 'Download CSV'}
                    csvFilename={utils.downloadFileName(
                        selectedProjectName,
                        selectedGroupBy === YEAR,
                        selectedStartDate,
                        selectedEndDate
                    )}
                />
            </section>
        </Loader>
    );
};
