import React from 'react';
import Table from 'cccisd-table';
import utils from '../../utils';
import style from './style.css';

export default ({ year, user, sickAvailable, sickCap, sickCarryover, sickTimeList }) => {
    const columns = [
        {
            name: 'month',
            label: 'Month',
            class: 'text-center',
            setClass: () => 'text-center',
            render: value => {
                return utils.monthToName(value);
            },
        },
        {
            name: 'sickUsed',
            label: 'Used',
            class: 'text-center',
            setClass: () => 'text-center',
        },
        {
            name: 'sickAvailable',
            label: 'Available',
            class: 'text-center',
            setClass: () => 'text-center',
        },
    ];

    return (
        <>
            <h4>
                <strong>{user?.fullName}</strong> - {year}
            </h4>
            <div className={style.summary}>
                <div>
                    Available: <strong>{sickAvailable}</strong>
                </div>
                <div>
                    Cap: <strong>{sickCap}</strong>
                </div>
                <div>
                    {year - 1} Carryover: <strong>{sickCarryover || 0}</strong>
                </div>
            </div>
            <Table
                name="sick-time-report"
                saveState={false}
                rowKey="month"
                perPage={1000}
                showPerPageOptions={false}
                columns={columns}
                data={sickTimeList}
                hideShowingRowsSummary
            />
        </>
    );
};
