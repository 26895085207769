import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import employeesQuery from './employees.graphql';
import Loader from 'cccisd-loader';
import VacationTable from '../../common/VacationModal/vacation-table';

const Body = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        getVacation();
    }, []);

    const getVacation = async () => {
        const response = await client.query({
            query: employeesQuery,
            fetchPolicy: 'network-only',
        });

        setReports(response.data.timeTracker.employeeList);
        setIsLoading(false);
    };

    return (
        <Loader type="inline" loading={isLoading} removeChildren>
            {reports.map(report => {
                return (
                    <section style={{ marginBottom: '50px' }}>
                        <VacationTable
                            key={report.user.userId}
                            year={report.pto.thisYear}
                            user={report.user}
                            vacationAvailable={report.vacationAvailable}
                            vacationAccrual={report.vacationAccrual}
                            vacationCap={report.vacationCap}
                            vacationCarryover={report.pto.vacationCarryover}
                            vacationList={report.ptoList}
                        />
                    </section>
                );
            })}
        </Loader>
    );
};

export default Body;
