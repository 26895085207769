import React, { useState } from 'react';
import { CccisdDatepicker } from 'cccisd-formik';
import ClickButton from 'cccisd-click-button';
import { client } from 'cccisd-apollo';
import commonUtils from '../../utils';
import notification from 'cccisd-notification';
import { Report, Page } from 'cccisd-laravel-report';
import style from './style.css';
import timesheetQuery from './timesheet.graphql';
import ReportTable from './report-table';
import utils from './utils';

export default ({ employeeId, firstName, lastName }) => {
    const [selectedDateInput, setSelectedDateInput] = useState(null);
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [supervisorFullName, setSupervisorFullName] = useState('');

    const mockForm = {
        errors: {},
        setFieldValue: (name, value) => {
            const date = value.split('-');
            setYear(Number.parseInt(date[0], 10));
            setMonth(Number.parseInt(date[1], 10));
            setSelectedDateInput(value);
        },
    };

    const generate = async () => {
        setIsLoading(true);

        const response = await client.query({
            query: timesheetQuery,
            fetchPolicy: 'network-only',
            variables: { employeeId, year, month },
        });

        const {
            groups: { projectList },
            timeTracker: {
                employee: { timeAdminList, supervisor },
            },
        } = response.data;

        if (!timeAdminList.length) {
            notification({
                type: 'danger',
                message: 'Timesheet does not exist for selected year and month',
            });
            setSupervisorFullName('');
            setColumns([]);
            setData([]);
            setIsLoading(false);
            return;
        }

        const table = utils.table(timeAdminList, projectList, year, month);

        setSupervisorFullName(supervisor.user.fullName);
        setColumns(table.columns);
        setData(table.data);
        setIsLoading(false);
    };

    const downloadFilename = `${firstName}-${lastName}-${commonUtils.monthToName(month)}-${year}`;

    return (
        <>
            <div className={style.form}>
                <span>Select year and month</span>
                <CccisdDatepicker
                    field={{ value: selectedDateInput, name: 'date' }}
                    form={mockForm}
                    showMonthYearPicker
                />
                <ClickButton
                    title="Generate Timesheet"
                    isLoading={isLoading}
                    isDisabled={!selectedDateInput}
                    className="btn btn-primary btn-sm"
                    onClick={generate}
                />
            </div>
            {data.length > 0 && !isLoading && (
                <div>
                    <Report downloadFilename={downloadFilename}>
                        <Page>
                            <div className={style.pageHeader}>
                                <h1>3C Institute Timesheet</h1>
                                <p>
                                    {firstName} {lastName} - {commonUtils.monthToName(month)} {year}
                                </p>
                            </div>
                            <ReportTable columns={columns} data={data} />
                            <div className={style.pageFooter}>
                                <p>
                                    Employee: {firstName} {lastName}
                                </p>
                                <p>Supervisor: {supervisorFullName}</p>
                                <p>
                                    <i>Signatures are electronically signed and certified</i>
                                </p>
                            </div>
                        </Page>
                    </Report>
                </div>
            )}
        </>
    );
};
