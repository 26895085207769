import React from 'react';
import axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import { Formik, Form, Field, CccisdInput, CccisdSelect } from 'cccisd-formik';

const Boilerplate = window.cccisd.boilerplate;

const Body = ({ supervisorList, callback }) => {
    const onSubmit = async (values, { setErrors }) => {
        const response = await axios.post(
            Boilerplate.route('api.monitoring.time-tracker.admin.employee-management.add-employee'),
            values
        );

        if (response.data.status === 'error') {
            return setErrors(response.data.errors);
        }

        callback();
    };

    const validate = values => {
        const errors = {};

        if (!values.email) {
            errors.email = 'Email is required';
        }
        if (!values.first_name) {
            errors.first_name = 'First name is required';
        }
        if (!values.last_name) {
            errors.last_name = 'Last name is required';
        }

        [
            { name: 'vacation_available', label: 'Vacation available' },
            { name: 'vacation_accrual', label: 'Vacation accrual' },
            { name: 'vacation_cap', label: 'Vacation cap' },
            { name: 'sick_available', label: 'Sick Time available' },
            { name: 'sick_cap', label: 'Sick Time cap' },
            { name: 'sick_accrual', label: 'Sick Time accrual' },
        ].forEach(field => {
            if (!values[field.name]) {
                errors[field.name] = `${field.label} is required`;
                return;
            }
            if (Number.isNaN(values[field.name]) || Number.isNaN(Number.parseFloat(values[field.name]))) {
                errors[field.name] = `${field.label} must be a number`;
                return;
            }
            if (values[field.name] < 0) {
                errors[field.name] = `${field.label} can not be a negative number`;
            }
        });

        return errors;
    };

    const initialValues = {
        supervisor_id: '0',
        vacation_available: '0',
        vacation_accrual: '10',
        vacation_cap: '120',
        sick_available: '48',
        sick_cap: '96',
        sick_accrual: '48',
    };

    return (
        <Formik
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            render={({ handleSubmit, isSubmitting }) => (
                <Form>
                    <Field
                        name="email"
                        component={CccisdInput}
                        label="Enter the 3C e-mail address for the new employee"
                        type="text"
                    />
                    <Field name="first_name" component={CccisdInput} label="First name" type="text" />
                    <Field name="last_name" component={CccisdInput} label="Last name" type="text" />
                    <Field name="supervisor_id" component={CccisdSelect} label="Supervisor" options={supervisorList} />
                    <Field
                        name="vacation_available"
                        component={CccisdInput}
                        label="Vacation available (unusual, employee given vacation at start)"
                        type="text"
                    />
                    <Field
                        name="vacation_accrual"
                        component={CccisdInput}
                        label="Vacation accrual for this month"
                        type="text"
                    />
                    <Field
                        name="vacation_cap"
                        component={CccisdInput}
                        label="Vacation cap (max vacation employee can have)"
                        type="text"
                    />
                    <Field
                        name="sick_available"
                        component={CccisdInput}
                        label="Sick Time available for the rest of this year"
                        type="text"
                    />
                    <Field
                        name="sick_cap"
                        component={CccisdInput}
                        label="Sick Time cap (max sick time employee can have)"
                        type="text"
                    />
                    <Field
                        name="sick_accrual"
                        component={CccisdInput}
                        label="Sick Time accrued on Jan 1st next year"
                        type="text"
                    />
                    <ClickButton
                        title="Submit"
                        type="submit"
                        className="btn btn-primary"
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                    />
                </Form>
            )}
        />
    );
};

export default Body;
