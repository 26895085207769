import format from 'date-fns/format';
import utils from '../utils';

const table = (employeeList, isByYear) => {
    if (isByYear) {
        return byYear(employeeList);
    }

    return byMonth(employeeList);
};

const byYear = employeeList => {
    const columns = {};
    let totalOverall = 0;
    let totalColumn = { employee: 'Total' };

    const data = employeeList.map(employee => {
        let totalRow = 0;

        const times = (employee.timesSummary.byYear || []).reduce((summary, current) => {
            const currentTotal = fixFloat(current.total);
            totalOverall += currentTotal;
            totalRow += currentTotal;
            totalColumn[current.year] = fixFloat((totalColumn[current.year] || 0) + currentTotal);
            columns[current.year] = Number.parseInt(current.year, 10);
            return { ...summary, [current.year]: currentTotal };
        }, {});

        return {
            employee: `${employee.user.lastName}, ${employee.user.firstName}`,
            ...times,
            total: fixFloat(totalRow),
        };
    });

    data.push({ ...totalColumn, total: fixFloat(totalOverall) });

    return {
        columns,
        data,
    };
};

const byMonth = employeeList => {
    const columns = {};
    let totalOverall = 0;
    let totalColumn = { employee: 'Total' };

    const data = employeeList.map(employee => {
        let totalRow = 0;

        const times = (employee.timesSummary.byYear || []).reduce((summary, current) => {
            const monthData = {};

            current.byMonth.forEach(month => {
                const monthName = month.month.toString().padStart(2, '0');
                const columnName = `${current.year}${monthName}`;
                const currentTotal = fixFloat(month.total);
                totalOverall += currentTotal;
                totalRow += currentTotal;
                totalColumn[columnName] = fixFloat((totalColumn[columnName] || 0) + currentTotal);
                columns[columnName] = Number.parseInt(columnName, 10);
                monthData[columnName] = currentTotal;
            });

            return { ...summary, ...monthData };
        }, {});

        return {
            employee: `${employee.user.lastName}, ${employee.user.firstName}`,
            ...times,
            total: fixFloat(totalRow),
        };
    });

    data.push({ ...totalColumn, total: fixFloat(totalOverall) });

    return {
        columns,
        data,
    };
};

const fixFloat = value => Number.parseFloat(value.toFixed(2));

const header = (name, isByYear) => {
    if (isByYear) {
        return name;
    }

    const year = name.substring(0, 4);
    const month = Number.parseInt(name.substring(4), 10);

    return `${utils.monthToShortName(month)} ${year}`;
};

const downloadFileName = (projectName, isByYear, startDate, endDate) => {
    if (!projectName) {
        return null;
    }

    const regex = /[^a-z0-9]/gi;
    let name = projectName.replace(regex, '-');

    name = `${name}--${isByYear ? 'Years' : 'Months'}`;

    if (startDate) {
        name = `${name}--From-${startDate}`;
    }

    if (endDate) {
        name = `${name}--To-${endDate}`;
    }

    return name;
};

const resolveValidDate = value => {
    const valueParts = value.split('/');

    if (valueParts.length !== 3) {
        return null;
    }

    const date = value ? format(value, 'MM/DD/YYYY') : null;

    if (!date) {
        return null;
    }

    const dateParts = date.split('/');

    if (
        Number.parseInt(valueParts[0], 10) === Number.parseFloat(dateParts[0], 10) &&
        Number.parseInt(valueParts[1], 10) === Number.parseFloat(dateParts[1], 10) &&
        Number.parseInt(valueParts[2], 10) === Number.parseFloat(dateParts[2], 10)
    ) {
        return format(value, 'YYYY-MM-DD');
    }

    return null;
};

export default {
    table,
    header,
    downloadFileName,
    resolveValidDate,
};
