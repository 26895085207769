import React from 'react';
import Notification from 'cccisd-notification';

const validationFailed = errorsCount => {
    Notification({
        message: (
            <span>
                Validation failed
                <br />
                {errorsCount === 1 && <>There is 1 validation error</>}
                {errorsCount > 1 && <>There are {errorsCount} validation errors</>}
            </span>
        ),
        type: 'danger',
        duration: 10000,
    });
};

const accessFailed = () => {
    Notification({
        message: 'Current week status does not allow any changes',
        type: 'danger',
        duration: 10000,
    });
};

export default {
    validationFailed,
    accessFailed,
};
