import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import myEmployeeQuery from './myEmployee.graphql';
import selfQuery from './self.graphql';
import VacationTable from './vacation-table';

const Body = ({ self = false, employeeId = undefined }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState();
    const [year, setYear] = useState();
    const [vacationAvailable, setVacationAvailable] = useState();
    const [vacationAccrual, setVacationAccrual] = useState();
    const [vacationCap, setVacationCap] = useState();
    const [vacationCarryover, setVacationCarryover] = useState();
    const [vacationList, setVacationList] = useState();

    useEffect(() => {
        getVacation();
    }, []);

    const getVacation = async () => {
        const query = self ? selfQuery : myEmployeeQuery;

        const response = await client.query({
            query,
            fetchPolicy: 'network-only',
            variables: { employeeId },
        });

        const { selfEmployee } = response.data.timeTracker;
        setUser(self ? selfEmployee.user : selfEmployee.myEmployee.user);
        setYear(self ? selfEmployee.pto.thisYear : selfEmployee.myEmployee.pto.thisYear);
        setVacationAvailable(self ? selfEmployee.vacationAvailable : selfEmployee.myEmployee.vacationAvailable);
        setVacationAccrual(self ? selfEmployee.vacationAccrual : selfEmployee.myEmployee.vacationAccrual);
        setVacationCap(self ? selfEmployee.vacationCap : selfEmployee.myEmployee.vacationCap);
        setVacationCarryover(self ? selfEmployee.pto.vacationCarryover : selfEmployee.myEmployee.pto.vacationCarryover);
        setVacationList(self ? selfEmployee.ptoList : selfEmployee.myEmployee.ptoList);

        setIsLoading(false);
    };

    return (
        <Loader type="inline" loading={isLoading} removeChildren>
            <VacationTable
                year={year}
                user={user}
                vacationAvailable={vacationAvailable}
                vacationAccrual={vacationAccrual}
                vacationCap={vacationCap}
                vacationCarryover={vacationCarryover}
                vacationList={vacationList}
            />
        </Loader>
    );
};

export default Body;
