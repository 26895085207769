import React, { useEffect, useState } from 'react';
import AddEmployeeModal from './AddEmployeeModal';
import axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import { client } from 'cccisd-apollo';
import employeesQuery from './employees.graphql';
import IconUser from 'cccisd-icons/user';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import PTOSettingsModal from './PTOSettingsModal';
import SickReportModal from './SickReportModal';
import Supervisors from './supervisors';
import style from './style.css';
import Table from 'cccisd-table';
import TimesheetModal from './TimesheetModal';
import VacationReportModal from './VacationReportModal';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

const BOTH = 'BOTH';
const UNARCHIVED = 'UNARCHIVED';

export default () => {
    const [isLoading, setIsLoading] = useState(true);
    const [archived, setArchived] = useState(UNARCHIVED);
    const [data, setData] = useState();
    const [supervisorList, setSupervisorList] = useState([]);

    useEffect(() => {
        loadEmployees();
    }, [archived]);

    const loadEmployees = async () => {
        setIsLoading(true);

        const response = await client.query({
            query: employeesQuery,
            fetchPolicy: 'network-only',
            variables: { archived },
        });

        const { employeeList } = response.data.timeTracker;

        setData(
            employeeList.map(employee => {
                return {
                    userId: employee.user.userId,
                    name: `${employee.user.lastName}, ${employee.user.firstName}`,
                    firstName: employee.user.firstName,
                    lastName: employee.user.lastName,
                    email: employee.user.email,
                    supervisor: employee.supervisor.user.userId || 0,
                    archived: employee.archived ? 1 : 0,
                    pawnId: employee.user.pawn.pawn.pawnId,
                    pawnHash: employee.user.pawn.pawn.pawnHash,
                };
            })
        );

        setSupervisorList([
            { value: 0, label: 'No Supervisor' },
            ...employeeList
                .filter(employee => !employee.archived)
                .map(employee => {
                    return { value: employee.user.userId, label: employee.user.fullName };
                }),
        ]);

        setIsLoading(false);
    };

    const onChangeSupervisor = async (userId, supervisorId) => {
        setIsLoading(true);
        await axios.post(Boilerplate.route(`api.monitoring.time-tracker.admin.employee-management.supervisor`), {
            employeeId: userId,
            supervisorId,
        });
        loadEmployees();
    };

    const onChangeEmployeeStatus = async (userId, archive) => {
        setIsLoading(true);
        const response = await axios.post(
            Boilerplate.route(`api.monitoring.time-tracker.admin.employee-management.status`),
            {
                employeeId: userId,
                archive: Boolean(archive),
            }
        );

        if (response.data.status === 'error') {
            notification({ type: 'danger', message: response.data.errors.status, duration: 10000 });
        }

        loadEmployees();
    };

    const onLoginAs = async (pawnId, pawnHash) => {
        const response = await axios.post(Boilerplate.route('api.nexus.user.loginAs'), { pawnId, pawnHash });

        if (response.data.status === 'success') {
            window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
        }

        if (response.data.status === 'error') {
            const msg =
                response.data.errors && typeof response.data.errors === 'string'
                    ? response.data.errors
                    : 'Login as another user failed';

            notification({ type: 'danger', message: msg });
        }
    };

    const columns = [
        {
            name: 'actions',
            label: 'Actions',
            render: (value, row) => {
                if (!row.pawnId) {
                    return null;
                }

                return (
                    <ClickButton
                        title={<IconUser />}
                        className="btn btn-xs btn-info"
                        onClick={() => onLoginAs(row.pawnId, row.pawnHash)}
                        isDisabled={isLoading}
                    />
                );
            },
        },
        {
            name: 'name',
            label: 'Name',
        },
        {
            name: 'email',
            label: 'Email',
        },
        {
            name: 'timesheet',
            label: 'Timesheet',
            render: (value, row) => {
                return (
                    <TimesheetModal
                        name={row.name}
                        employeeId={row.userId}
                        firstName={row.firstName}
                        lastName={row.lastName}
                        trigger={
                            <button type="button" className="btn btn-sm btn-default" disabled={isLoading}>
                                Timesheet
                            </button>
                        }
                    />
                );
            },
        },
        {
            name: 'supervisor',
            label: 'Supervisor',
            render: (value, row) => (
                <Supervisors
                    supervisorList={supervisorList}
                    selectedSupervisorId={value}
                    onChange={supervisorId => onChangeSupervisor(row.userId, supervisorId)}
                />
            ),
        },
        {
            name: 'archived',
            label: 'Employee Status',
            render: (value, row) => (
                <select
                    className="form-control"
                    value={value}
                    onChange={event => onChangeEmployeeStatus(row.userId, Number.parseInt(event.target.value, 10))}
                >
                    <option key="0" value={0}>
                        Current Employee
                    </option>
                    <option key="1" value={1}>
                        Former Employee
                    </option>
                </select>
            ),
        },
    ];

    return (
        <>
            <section className={style.header}>
                <select
                    className="form-control"
                    value={archived}
                    onChange={event => setArchived(event.target.value)}
                    disabled={isLoading}
                >
                    <option key={UNARCHIVED} value={UNARCHIVED}>
                        Current Employees Only
                    </option>
                    <option key={BOTH} value={BOTH}>
                        Current and Former Employees
                    </option>
                </select>
                <div>
                    <PTOSettingsModal
                        trigger={
                            <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                PTO Settings
                            </button>
                        }
                    />
                    <VacationReportModal
                        trigger={
                            <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                Vacation Report
                            </button>
                        }
                    />
                    <SickReportModal
                        trigger={
                            <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                Sick Report
                            </button>
                        }
                    />
                    <AddEmployeeModal
                        supervisorList={supervisorList}
                        callback={loadEmployees}
                        trigger={
                            <button type="button" className="btn btn-sm btn-info" disabled={isLoading}>
                                Add Employee
                            </button>
                        }
                    />
                </div>
            </section>
            <Loader loading={isLoading} removeChildren>
                <section className={style.employeeManagementTable}>
                    <Table
                        name="employee-management-table"
                        saveState={false}
                        rowKey="userId"
                        perPage={1000}
                        showPerPageOptions={false}
                        columns={columns}
                        data={data}
                        hideShowingRowsSummary
                    />
                </section>
            </Loader>
        </>
    );
};
