import React, { useEffect, useState } from 'react';
import axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import { client } from 'cccisd-apollo';
import { Formik, Field, Form, CccisdToggle } from 'cccisd-formik';
import Loader from 'cccisd-loader';
import projectsQuery from './projects.graphql';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const TimeTracker = window.cccisd.timeTracker;

const Body = ({ close, callback }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        getProjects();
    }, []);

    const getProjects = async () => {
        const response = await client.query({
            query: projectsQuery,
            fetchPolicy: 'network-only',
            variables: { adminProjectId: TimeTracker.config['admin-project-id'] },
        });

        setProjects(
            response.data.groups.projectList.map(project => ({
                projectId: project.group.groupId,
                label: project.group.label,
            }))
        );

        let init = response.data.timeTracker.selfEmployee.visibleProjectList.reduce((current, project) => {
            current[project.projectId] = true;
            return current;
        }, {});

        if (Object.keys(init).length === 0) {
            init = response.data.groups.projectList.reduce((current, project) => {
                current[project.group.groupId] = true;
                return current;
            }, {});
        }

        setInitialValues(init);

        setIsLoading(false);
    };

    const onSubmit = async values => {
        let visible = Object.keys(values).reduce((current, projectId) => {
            if (values[projectId]) {
                current.push(projectId);
            }

            return current;
        }, []);

        if (visible.length === projects.length) {
            visible = [];
        }

        await axios.post(Boilerplate.route(`api.monitoring.time-tracker.my-time.visible-projects`), {
            projectIds: visible,
        });

        callback();
        close();
    };

    return (
        <Loader type="inline" loading={isLoading} removeChildren>
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, isSubmitting, values, setFieldValue }) => {
                    const visible = Object.values(values).filter(v => v).length;

                    return (
                        <Form>
                            <div className="row">
                                <div className="col-xs-9">
                                    <div className={style.controls}>
                                        <strong>Visible</strong>: <strong>{visible}</strong>
                                        <ClickButton
                                            className="btn btn-sm btn-default"
                                            title="Select All"
                                            onClick={() => projects.forEach(p => setFieldValue(p.projectId, true))}
                                        />
                                        <ClickButton
                                            className="btn btn-sm btn-default"
                                            title="Deselect All"
                                            onClick={() => projects.forEach(p => setFieldValue(p.projectId, false))}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-3">
                                    <div className={style.submit}>
                                        <ClickButton
                                            className="btn btn-sm btn-primary"
                                            title="Submit"
                                            onClick={handleSubmit}
                                            isLoading={isSubmitting}
                                            isDisabled={visible === 0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style.projects}>
                                {projects.map(project => (
                                    <div key={project.projectId}>
                                        <Field
                                            name={project.projectId}
                                            component={CccisdToggle}
                                            label={project.label}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Form>
                    );
                }}
            />
        </Loader>
    );
};

export default Body;
