const validate = values => {
    const errors = {};

    const ids = Object.keys(values.timeData);

    ids.forEach(id => {
        const dates = Object.keys(values.timeData[id]);

        dates.forEach(date => {
            const msg = rules(values.timeData[id][date]);

            if (msg) {
                errors[`timeData.${id}.${date}`] = msg;
            }
        });
    });

    return errors;
};

const rules = value => {
    if (value === '') {
        return null;
    }

    if (Number.isNaN(parseFloat(value))) {
        return 'Time must be a number';
    }

    if (value < 0) {
        return 'Time must be a positive number';
    }

    if (value > 24) {
        return 'Time can not exceed 24 hours';
    }

    const parts = value.toString().split('.');
    if (parts.length > 1 && parts[1].length > 2) {
        return 'Time must be rounded to 2 decimal places';
    }

    return null;
};

export default validate;
