import React, { useState } from 'react';
import axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import { client } from 'cccisd-apollo';
import { Field, Form, Formik } from 'cccisd-formik';
import InputFile from '../../common/InputFile';
import totalsQuery from './totals.graphql';
import utils from './utils';

const Boilerplate = window.cccisd.boilerplate;

const Body = ({ year, month, callback }) => {
    const [isDownloading, setIsDownloading] = useState();

    const downloadCsv = async () => {
        setIsDownloading(true);

        const response = await client.query({
            query: totalsQuery,
            fetchPolicy: 'network-only',
            variables: {
                year,
                month,
            },
        });

        const {
            groups: { projectList },
            timeTracker: { employeeList },
        } = response.data;

        utils.downloadCsv(projectList, employeeList, year, month);

        setIsDownloading(false);
    };

    const onSubmit = async values => {
        const formData = new FormData();
        formData.append('csv_file', values.csv_file);
        formData.append('year', year);
        formData.append('month', month);

        await axios.post(Boilerplate.route('api.monitoring.time-tracker.admin.adjust-month'), formData);
        callback();
    };

    return (
        <div>
            <h4>Export Raw Totals</h4>
            <button type="button" className="btn btn-sm btn-primary" onClick={downloadCsv} disabled={isDownloading}>
                Download Totals
            </button>
            <h4 style={{ marginTop: '50px' }}>Upload Adjusted Numbers</h4>
            <Formik
                onSubmit={onSubmit}
                render={({ handleSubmit, isSubmitting, values }) => (
                    <Form>
                        <Field name="csv_file" component={InputFile} label="CSV File" disabled={isSubmitting} />
                        {values.csv_file && (
                            <ClickButton
                                title="Process Adjusted Numbers"
                                type="submit"
                                className="btn btn-primary btn-sm"
                                style={{ marginTop: '15px' }}
                                isLoading={isSubmitting}
                                onClick={handleSubmit}
                            />
                        )}
                    </Form>
                )}
            />
        </div>
    );
};

export default Body;
