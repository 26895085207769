import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import employeesQuery from './employees.graphql';
import Loader from 'cccisd-loader';
import SickTimeTable from '../../common/SickTimeModal/sick-time-table';

const Body = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        getSickTime();
    }, []);

    const getSickTime = async () => {
        const response = await client.query({
            query: employeesQuery,
            fetchPolicy: 'network-only',
        });

        setReports(response.data.timeTracker.employeeList);
        setIsLoading(false);
    };

    return (
        <Loader type="inline" loading={isLoading} removeChildren>
            {reports.map(report => {
                return (
                    <section style={{ marginBottom: '50px' }}>
                        <SickTimeTable
                            key={report.user.userId}
                            year={report.pto.thisYear}
                            user={report.user}
                            sickAvailable={report.sickAvailable}
                            sickCap={report.sickCap}
                            sickCarryover={report.pto.sickCarryover}
                            sickTimeList={report.ptoList}
                        />
                    </section>
                );
            })}
        </Loader>
    );
};

export default Body;
