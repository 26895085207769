const rejectDefaultMsg = (employeeFirstName, myFullName, weekFrom) => {
    return `${employeeFirstName},

I have rejected your time data for the week starting on ${weekFrom}. Please come see me for more information.

${myFullName}`;
};

export default {
    rejectDefaultMsg,
};
