import React, { useRef } from 'react';
import Body from './body';
import Modal from 'cccisd-modal';

export default props => {
    const addEmployeeModal = useRef();

    return (
        <Modal ref={addEmployeeModal} trigger={props.trigger} title="Add Employee">
            <Body
                {...props}
                callback={() => {
                    props.callback();
                    addEmployeeModal.current.close();
                }}
            />
        </Modal>
    );
};
