import React from 'react';

export default ({ supervisorList, selectedSupervisorId, onChange }) => (
    <select
        className="form-control"
        value={selectedSupervisorId}
        onChange={event => onChange(Number.parseInt(event.target.value, 10))}
    >
        {supervisorList.map(employee => (
            <option key={employee.value} value={employee.value}>
                {employee.label}
            </option>
        ))}
    </select>
);
