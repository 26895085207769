import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { client } from 'cccisd-apollo';

import supportHoursQuery from './supportHours.graphql';
import {
    janToJune,
    julyToDec,
    renderAlert,
    semesterTotal,
    thisYear,
    getMonthOfDate,
    yearSettings,
} from '../../dashboardHelpers.js';
import Callout from '../../../components/Callout';

const PerClientView = props => {
    const { selectedOrg } = props;

    if (!selectedOrg) {
        return <Loader loading type="inline" />;
    }

    const [hours, setHours] = useState(true);
    const [chartData, setChartData] = useState([]);

    const {
        mainUrl,
        variableName,
        group: { label },
        fields: { supportHours, billingCycle },
    } = selectedOrg;

    useEffect(() => {
        (async () => {
            try {
                await client
                    .query({
                        query: supportHoursQuery,
                        variables: { clientName: variableName },
                        fetchPolicy: 'network-only',
                    })
                    .then(response => {
                        const allClientFlows = response.data.flows.assignmentProgressList;

                        setHours(allClientFlows);

                        const getMonthTotal = (monthId, year) => {
                            if (allClientFlows && allClientFlows.length > 0) {
                                const byMonth = allClientFlows.filter(flow => flow.devTags.supportDate.includes(year));

                                if (byMonth.length > 0) {
                                    const filterMonth = byMonth.filter(
                                        flow => getMonthOfDate(flow.devTags.supportDate) === monthId
                                    );

                                    if (filterMonth.length > 0) {
                                        const monthTotal = filterMonth
                                            .map(flow => +flow.devTags.supportMinutes)
                                            .reduce((a, b) => a + b);

                                        const inHours = (monthTotal / 60).toFixed(2);

                                        return inHours;
                                    }
                                    return 0;
                                }
                                return 0;
                            }

                            return 0;
                        };

                        const getCalendarYearTotal = year => {
                            if (allClientFlows && allClientFlows.length > 0) {
                                const byYear = allClientFlows.filter(flow => flow.devTags.supportDate.includes(year));

                                if (byYear.length > 0) {
                                    const total = byYear
                                        .map(flow => +flow.devTags.supportMinutes)
                                        .reduce((a, b) => a + b);

                                    const inHours = (total / 60).toFixed(2);

                                    return inHours;
                                }
                                return 0;
                            }

                            return 0;
                        };

                        const getMidYearTotal = (fallSemesterYear, springSemesterYear) => {
                            const fallYear = allClientFlows.filter(tableClient =>
                                tableClient.devTags.supportDate.includes(fallSemesterYear)
                            );

                            const springYear = allClientFlows.filter(tableClient =>
                                tableClient.devTags.supportDate.includes(springSemesterYear)
                            );

                            const fallMonths =
                                fallYear.length > 0
                                    ? fallYear.filter(flow =>
                                          julyToDec.includes(getMonthOfDate(flow.devTags.supportDate))
                                      )
                                    : false;

                            const springMonths =
                                springYear.length > 0
                                    ? springYear.filter(flow =>
                                          janToJune.includes(getMonthOfDate(flow.devTags.supportDate))
                                      )
                                    : false;

                            const fallTotal = fallMonths && fallMonths.length > 0 ? semesterTotal(fallMonths) : 0;
                            const springTotal =
                                springMonths && springMonths.length > 0 ? semesterTotal(springMonths) : 0;

                            const midYearTotal = (fallTotal + springTotal) / 60;

                            return midYearTotal > 0 ? midYearTotal.toFixed(2) : 0;
                        };

                        const calendarYear = [
                            {
                                month: 'Jan',
                                hours: getMonthTotal(1, thisYear),
                            },
                            {
                                month: 'Feb',
                                hours: getMonthTotal(2, thisYear),
                            },
                            {
                                month: 'Mar',
                                hours: getMonthTotal(3, thisYear),
                            },
                            {
                                month: 'Apr',
                                hours: getMonthTotal(4, thisYear),
                            },
                            {
                                month: 'May',
                                hours: getMonthTotal(5, thisYear),
                            },
                            {
                                month: 'Jun',
                                hours: getMonthTotal(6, thisYear),
                            },
                            {
                                month: 'Jul',
                                hours: getMonthTotal(7, thisYear),
                            },
                            {
                                month: 'Aug',
                                hours: getMonthTotal(8, thisYear),
                            },
                            {
                                month: 'Sept',
                                hours: getMonthTotal(9, thisYear),
                            },
                            {
                                month: 'Oct',
                                hours: getMonthTotal(10, thisYear),
                            },
                            {
                                month: 'Nov',
                                hours: getMonthTotal(11, thisYear),
                            },
                            {
                                month: 'Dec',
                                hours: getMonthTotal(12, thisYear),
                            },
                            {
                                month: 'Total',
                                'total hours': getCalendarYearTotal(thisYear),
                            },
                        ];

                        const midYear = [
                            {
                                month: 'Jul',
                                hours: getMonthTotal(7, yearSettings.midYear.currentYear.fallSemesterYear),
                            },
                            {
                                month: 'Aug',
                                hours: getMonthTotal(8, yearSettings.midYear.currentYear.fallSemesterYear),
                            },
                            {
                                month: 'Sept',
                                hours: getMonthTotal(9, yearSettings.midYear.currentYear.fallSemesterYear),
                            },
                            {
                                month: 'Oct',
                                hours: getMonthTotal(10, yearSettings.midYear.currentYear.fallSemesterYear),
                            },
                            {
                                month: 'Nov',
                                hours: getMonthTotal(11, yearSettings.midYear.currentYear.fallSemesterYear),
                            },
                            {
                                month: 'Dec',
                                hours: getMonthTotal(12, yearSettings.midYear.currentYear.fallSemesterYear),
                            },
                            {
                                month: 'Jan',
                                hours: getMonthTotal(1, yearSettings.midYear.currentYear.springSemesterYear),
                            },
                            {
                                month: 'Feb',
                                hours: getMonthTotal(2, yearSettings.midYear.currentYear.springSemesterYear),
                            },
                            {
                                month: 'Mar',
                                hours: getMonthTotal(3, yearSettings.midYear.currentYear.springSemesterYear),
                            },
                            {
                                month: 'Apr',
                                hours: getMonthTotal(4, yearSettings.midYear.currentYear.springSemesterYear),
                            },
                            {
                                month: 'May',
                                hours: getMonthTotal(5, yearSettings.midYear.currentYear.springSemesterYear),
                            },
                            {
                                month: 'Jun',
                                hours: getMonthTotal(6, yearSettings.midYear.currentYear.springSemesterYear),
                            },
                            {
                                month: 'Total',
                                'total hours': getMidYearTotal(
                                    yearSettings.midYear.currentYear.fallSemesterYear,
                                    yearSettings.midYear.currentYear.springSemesterYear
                                ),
                            },
                        ];

                        setChartData(billingCycle === 'calendar' ? calendarYear : midYear);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [selectedOrg]);

    const renderBurn = () => {
        let yearTotal = chartData && chartData.length > 0 && chartData.filter(bar => bar.month === 'Total');

        if (yearTotal.length === 1) {
            yearTotal = +yearTotal[0]['total hours'];
        }

        const burnRate = ((yearTotal / +supportHours) * 100).toFixed();

        return <h3>{`${burnRate}%`}</h3>;
    };

    return (
        <>
            <div>
                <h2>{label}</h2>
                <div>
                    <a href={mainUrl} target="_blank" rel="noopener noreferrer">
                        {mainUrl}
                    </a>
                </div>
            </div>
            {hours.length > 0 ? (
                <div>
                    <div style={{ width: '13em', marginTop: '1em' }}>
                        <Callout id={`Bank: ${supportHours} hours`} rating={renderBurn()} />
                    </div>
                    <div style={{ width: '100%', height: '500px' }}>
                        <ResponsiveBar
                            data={chartData}
                            keys={['hours', 'total hours']}
                            indexBy="month"
                            margin={{ top: 50, right: 130, bottom: 70, left: 65 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={['#E17D26', '#007298']}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Month',
                                legendPosition: 'middle',
                                legendOffset: 50,
                                truncateTickAt: 0,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Support Hours',
                                legendPosition: 'middle',
                                legendOffset: -60,
                                truncateTickAt: 0,
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor="white"
                            legends={[]}
                            markers={[
                                {
                                    axis: 'y',
                                    value: `${supportHours}`,
                                    lineStyle: {
                                        stroke: '#cc3232',
                                        strokeWidth: 1,
                                    },
                                },
                            ]}
                            role="application"
                            ariaLabel="Per Client view bar chart"
                            barAriaLabel={e => `${e.id}: ${e.formattedValue}`}
                        />
                    </div>
                </div>
            ) : (
                renderAlert('No support hours submitted yet for this year.', 'hourglass')
            )}
        </>
    );
};

PerClientView.propTypes = {
    selectedOrg: PropTypes.object,
};

export default PerClientView;
