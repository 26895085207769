import React, { useState, useEffect } from 'react';
import TableTop from 'cccisd-tabletop';
import byPackage from './packages.graphql';
import axios from 'cccisd-axios';
import UpArrow from 'cccisd-icons/arrow-up8';
import DownArrow from 'cccisd-icons/arrow-down8';

const PackagesList = () => {
    // this is a list of all packages based on their git path.
    const options = [
        'access-log',
        'app-definitions',
        'artisan-commands',
        'assignment',
        'AudioProduction2',
        'batchelor',
        'boilerplate',
        'event-log',
        'flow',
        'graphql',
        'legosadmin',
        'licensing',
        'metrics',
        'nexus',
        'polytags',
        'report',
        'resource-storage',
        'route-cache',
        'selwebee',
        'selweble',
        'short-urls',
        'tabulator',
        'task-master',
    ];
    const [selectedPackage, setSelectedPackage] = useState(options[1]);
    const [tagList, setTagList] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');

    useEffect(() => {
        const getTag = async () => {
            if (selectedPackage !== '') {
                const response = await axios.get('/api/monitoring/packageTags?package=' + selectedPackage);
                if (response.data.success === true) {
                    setTagList(response.data.data);
                    setSelectedTag(response.data.data[0]);
                }
            }
        };
        getTag();
    }, [selectedPackage]);

    useEffect(() => {
        console.log(tagList);
        console.log(selectedTag);
    }, [tagList, selectedTag]);

    const PackageDropdown = () => {
        return (
            <div>
                <form className="form-inline">
                    <div className="form-group">
                        <label
                            htmlFor="site"
                            style={{
                                marginRight: '5px',
                                marginBottom: '0px',
                                marginTop: '5px',
                            }}
                        >
                            Package
                        </label>
                        <select
                            value={selectedPackage}
                            className="form-control"
                            id="site"
                            onChange={e => setSelectedPackage(e.target.value)}
                        >
                            {options.map(value => (
                                <option value={value} key={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group" style={{ marginLeft: '10px' }}>
                        <label
                            htmlFor="tag"
                            style={{
                                marginRight: '5px',
                                marginBottom: '0px',
                                marginTop: '5px',
                            }}
                        >
                            Tag
                        </label>
                        <select
                            value={selectedTag}
                            className="form-control"
                            id="tag"
                            onChange={e => {
                                let desiredTag = tagList.find(tag => tag === e.target.value);
                                setSelectedTag(desiredTag);
                            }}
                        >
                            {tagList.map(o => (
                                <option key={o} value={o}>
                                    {o}
                                </option>
                            ))}
                        </select>
                    </div>
                </form>
                <hr />
            </div>
        );
    };
    const RenderByPackage = () => {
        function sortVersion(version) {
            const [numericPart = '', suffixPart = ' '] = (version || '').split('_');
            const [major = 0, minor = 0, patch = 0] = (numericPart || '').split('.');

            const paddedMajor = String(major).padStart(4, '0');
            const paddedMinor = String(minor).padStart(4, '0');
            const paddedPatch = String(patch).padStart(4, '0');

            const sortedVersion = `${paddedMajor}.${paddedMinor}.${paddedPatch}`;

            return suffixPart ? `${sortedVersion}_${suffixPart}` : sortedVersion;
        }

        function compareVersions(selectedTag, appVersion) {
            const sortedSelectedTag = sortVersion(selectedTag);
            const sortedAppVersion = sortVersion(appVersion);

            if (sortedSelectedTag === sortedAppVersion) {
                return 0;
            }

            const selectedParts = sortedSelectedTag.split('.');
            const appParts = sortedAppVersion.split('.');

            for (let i = 0; i < selectedParts.length; i++) {
                const selectedNum = parseInt(selectedParts[i], 10);
                const appNum = parseInt(appParts[i], 10);

                if (selectedNum !== appNum) {
                    return selectedNum - appNum;
                }
            }

            const suffixA = (selectedTag || '').split('_')[1];
            const suffixB = (appVersion || '').split('_')[1];
            return suffixA && suffixB ? suffixA.localeCompare(suffixB) : 0;
        }

        const renders = {
            iconRenderProd: props => {
                const currentVersion =
                    props.row[`group.settings.environments.prod.dependencies.cccisd/${selectedPackage}`];
                if (typeof currentVersion === 'string') {
                    const comparisonResult = compareVersions(selectedTag, currentVersion);
                    if (comparisonResult <= 0) {
                        return (
                            <div className="text-success text-center">
                                {currentVersion}
                                <UpArrow />
                            </div>
                        );
                    }
                    return (
                        <div className="text-danger text-center">
                            {currentVersion}
                            <DownArrow />
                        </div>
                    );
                }
                return <div />;
            },
            iconRenderQA: props => {
                const currentVersion =
                    props.row[`group.settings.environments.qa.dependencies.cccisd/${selectedPackage}`];
                if (typeof currentVersion === 'string') {
                    const comparisonResult = compareVersions(selectedTag, currentVersion);
                    if (comparisonResult <= 0) {
                        return (
                            <div className="text-success text-center">
                                {currentVersion}
                                <UpArrow />
                            </div>
                        );
                    }
                    return (
                        <div className="text-danger text-center">
                            {currentVersion}
                            <DownArrow />
                        </div>
                    );
                }
                return <div />;
            },
            iconRenderStaging: props => {
                const currentVersion =
                    props.row[`group.settings.environments.staging.dependencies.cccisd/${selectedPackage}`];
                if (typeof currentVersion === 'string') {
                    const comparisonResult = compareVersions(selectedTag, currentVersion);
                    if (comparisonResult <= 0) {
                        return (
                            <div className="text-success text-center">
                                {currentVersion}
                                <UpArrow />
                            </div>
                        );
                    }
                    return (
                        <div className="text-danger text-center">
                            {currentVersion}
                            <DownArrow />
                        </div>
                    );
                }
                return <div />;
            },
        };

        return (
            <div>
                <TableTop
                    handle="byPackage"
                    views={[
                        {
                            handle: 'byPackage',
                            title: 'Package List',
                            table: {
                                query: byPackage,
                                rowKey: 'group.groupId',
                                renders,
                                columns: [
                                    {
                                        name: 'group.label',
                                        label: 'Name',
                                        tooltip: <div>This is the internal name for the app's production site.</div>,
                                        sort: true,
                                        filter: true,
                                    },
                                    {
                                        name: 'fields.git_handle',
                                        label: 'Handle',
                                        tooltip: (
                                            <div>This is the current git handle for the app's production site.</div>
                                        ),
                                        sort: true,
                                        filter: true,
                                    },
                                    {
                                        name: `group.settings.environments.prod.dependencies.cccisd/${selectedPackage}`,
                                        label: 'Production',
                                        render: 'iconRenderProd',
                                        tooltip: (
                                            <div>
                                                This is the selected package's version for the app's production site.
                                            </div>
                                        ),
                                        sort: true,
                                        filter: true,
                                    },
                                    {
                                        name: `group.settings.environments.qa.dependencies.cccisd/${selectedPackage}`,
                                        label: 'QA',
                                        render: 'iconRenderQA',
                                        tooltip: (
                                            <div>This is the selected package's version for the app's qa site.</div>
                                        ),
                                        sort: true,
                                        filter: true,
                                    },
                                    {
                                        name: `group.settings.environments.staging.dependencies.cccisd/${selectedPackage}`,
                                        label: 'Staging',
                                        render: 'iconRenderStaging',
                                        tooltip: (
                                            <div>
                                                This is the selected package's version for the app's staging site.
                                            </div>
                                        ),
                                        sort: true,
                                        filter: true,
                                    },
                                ],
                            },
                        },
                    ]}
                />
            </div>
        );
    };
    return (
        <div>
            <PackageDropdown />
            <RenderByPackage />
        </div>
    );
};

export default PackagesList;
