import React from 'react';
import Body from './body';
import Modal from 'cccisd-modal';
import utils from '../../utils';

export default props => (
    <Modal trigger={props.trigger} title={`${utils.monthToName(props.month)} ${props.year}`} size="large">
        <Body {...props} />
    </Modal>
);
