const validate = values => {
    const errors = {};

    const ids = Object.keys(values.pto);

    ids.forEach(id => {
        const settings = Object.keys(values.pto[id]);

        settings.forEach(setting => {
            const msg = rules(values.pto[id][setting]);

            if (msg) {
                errors[`pto.${id}.${setting}`] = msg;
            }
        });
    });

    return errors;
};

const rules = value => {
    if (value === '') {
        return 'PTO setting is required';
    }

    if (Number.isNaN(parseFloat(value))) {
        return 'PTO setting must be a number';
    }

    const parts = value.toString().split('.');
    if (parts.length > 1 && parts[1].length > 2) {
        return 'PTO setting must be rounded to 2 decimal places';
    }

    return null;
};

export default validate;
