import React, { useEffect, useState } from 'react';
import axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import { Formik, Form, Field } from 'cccisd-formik';
import { client } from 'cccisd-apollo';
import employeesQuery from './employees.graphql';
import InputTime from '../../common/InputTime';
import Loader from 'cccisd-loader';
import style from './style.css';
import Table from 'cccisd-table';
import validate from './validate';

const Boilerplate = window.cccisd.boilerplate;

const Body = ({ callback }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        getEmployees();
    }, []);

    const getEmployees = async () => {
        const response = await client.query({
            query: employeesQuery,
            fetchPolicy: 'network-only',
        });

        const initialPTO = { pto: {} };

        setData(
            response.data.timeTracker.employeeList.map(employee => {
                initialPTO.pto[employee.user.userId] = {};
                initialPTO.pto[employee.user.userId].vacation_accrual = employee.vacationAccrual;
                initialPTO.pto[employee.user.userId].vacation_cap = employee.vacationCap;
                initialPTO.pto[employee.user.userId].sick_accrual = employee.sickAccrual;
                initialPTO.pto[employee.user.userId].sick_cap = employee.sickCap;
                initialPTO.pto[employee.user.userId].sick_available = employee.sickAvailable;
                initialPTO.pto[employee.user.userId].vacation_available = employee.vacationAvailable;

                return {
                    ...employee,
                    id: employee.user.userId,
                    employee: `${employee.user.lastName}, ${employee.user.firstName}`,
                };
            })
        );

        setInitialValues(initialPTO);
        setIsLoading(false);
    };

    const columns = [
        {
            name: 'employee',
            label: 'Employee',
        },
        {
            name: 'vacationAccrual',
            label: 'Vacation Accrual (this month)',
            render: (value, row) => (
                <div>
                    <Field name={`pto.${row.id}.vacation_accrual`} component={InputTime} />
                </div>
            ),
        },
        {
            name: 'vacationCap',
            label: 'Vacation Cap',
            render: (value, row) => (
                <div>
                    <Field name={`pto.${row.id}.vacation_cap`} component={InputTime} />
                </div>
            ),
        },
        {
            name: 'sickAccrual',
            label: 'Sick Accrual (on Jan 1st)',
            render: (value, row) => (
                <div>
                    <Field name={`pto.${row.id}.sick_accrual`} component={InputTime} />
                </div>
            ),
        },
        {
            name: 'sickCap',
            label: 'Sick Cap',
            render: (value, row) => (
                <div>
                    <Field name={`pto.${row.id}.sick_cap`} component={InputTime} />
                </div>
            ),
        },
        {
            name: 'sickAvailable',
            label: 'Available Sick Time',
            render: (value, row) => (
                <div>
                    <Field name={`pto.${row.id}.sick_available`} component={InputTime} />
                </div>
            ),
        },
        {
            name: 'vacationAvailable',
            label: 'Available Vacation',
            render: (value, row) => (
                <div>
                    <Field name={`pto.${row.id}.vacation_available`} component={InputTime} />
                </div>
            ),
        },
    ];

    const onSubmit = async values => {
        await axios.post(
            Boilerplate.route('api.monitoring.time-tracker.admin.employee-management.update-pto-settings'),
            values
        );
        callback();
    };

    return (
        <Loader type="inline" loading={isLoading} removeChildren>
            <section className={style.ptoTable}>
                <Formik
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={initialValues}
                    render={({ handleSubmit, isSubmitting }) => {
                        return (
                            <Form>
                                <Table
                                    name="pto-settings-table"
                                    saveState={false}
                                    rowKey="id"
                                    perPage={1000}
                                    showPerPageOptions={false}
                                    columns={columns}
                                    data={data}
                                    hideShowingRowsSummary
                                />
                                <ClickButton
                                    title="Submit"
                                    type="submit"
                                    className="btn btn-primary"
                                    isLoading={isSubmitting}
                                    onClick={handleSubmit}
                                />
                            </Form>
                        );
                    }}
                />
            </section>
        </Loader>
    );
};

export default Body;
