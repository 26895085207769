import utils from '../../utils';

const prepare = string => {
    string = string.replace(/(<([^>]+)>)/gi, '');
    return '"' + string.replace(/"/, '') + '"';
};

const headers = projectList => {
    return [
        prepare('UserId'),
        prepare('Employee'),
        prepare('Total'),
        ...projectList.map(project => prepare(project.group.label)),
    ];
};

const rows = (projectList, employeeList) => {
    return employeeList.map(employee => {
        const employeeTotals = (employee.timeAdminSummary.byProject || []).reduce((obj, curr) => {
            obj[curr.projectId] = curr.total;
            return obj;
        }, {});

        const projectTotals = projectList.map(project => {
            return project.group.groupId in employeeTotals ? employeeTotals[project.group.groupId].toFixed(2) : 0;
        });

        const total = projectTotals.reduce((sum, t) => sum + Number.parseFloat(t), 0);

        return [
            employee.user.userId,
            prepare(`${employee.user.lastName}, ${employee.user.firstName}`),
            total > 0 ? total.toFixed(2) : total,
            ...projectTotals,
        ];
    });
};

const downloadCsv = (projectList, employeeList, year, month) => {
    const excelUniversalBOM = '\uFEFF';

    const content =
        excelUniversalBOM +
        [headers(projectList), ...rows(projectList, employeeList)].map(row => row.join(',')).join('\n');

    const url = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content);

    const link = document.createElement('a');
    link.style.display = 'none';
    link.download = `Adjust-Timesheets-${utils.monthToName(month)}-${year}`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default {
    downloadCsv,
};
