import React from 'react';
import style from './style.css';
import Table from 'cccisd-table';

export default ({ columns, data }) => {
    const perTable = 8;

    const dateColumn = columns.shift();
    const totalColumn = columns.pop();

    const tables = [];

    for (let i = 0; i < columns.length; i += perTable) {
        const chunk = columns.slice(i, i + perTable);
        tables.push(chunk);
    }

    return tables.map((tableColumns, index) => {
        let thisTableColumns = [dateColumn, ...tableColumns];

        if (tables.length - 1 === index) {
            thisTableColumns = [...thisTableColumns, totalColumn];
        }

        return (
            <div className={style.reportTable}>
                <Table
                    name="report-table"
                    saveState={false}
                    rowKey="day"
                    perPage={1000}
                    showPerPageOptions={false}
                    columns={thisTableColumns}
                    data={data}
                    wrapperClassName={style.tableWrapper}
                    hideShowingRowsSummary
                />
            </div>
        );
    });
};
